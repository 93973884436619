<template lang="pug">
#print-label
  h1 Print Label
  CloverItemSelect(:v-model="toPrint")
  DymoLabel(
    v-if="toPrint",
    :dymo="dymo",
    :label_xml="LABEL_XML",
    :clover_id="variants[toPrint].clover_id",
    :scientific_name="variants[toPrint].species.scientific_name",
    :common_name="variants[toPrint].species.common_name",
    :description="variants[toPrint].description",
    :qt_date="null",
    :quantity="null",
    :price="null"
  )
</template>

<script>
import { mapGetters } from "vuex";
import LABEL_XML from "!raw-loader!@/assets/xml/label1.xml";
import Dymo from "dymojs";
import DymoLabel from "@/components/DymoLabel.vue";
import CloverItemSelect from "@/components/select/CloverItemSelect.vue";

export default {
  name: "PrintSticker",
  components: {
    DymoLabel,
    CloverItemSelect,
  },
  data() {
    return {
      toPrint: null,
      dymo: null,
      LABEL_XML,
    };
  },
  computed: {
    variants() {
      const variants = {};
      if (this.getSpecies.length > 0) {
        this.getSpecies.forEach((species) => {
          species.variants
            .filter((variant) => variant.clover_id)
            .forEach((variant) => {
              variant.species = species;
              variants[variant.id] = variant;
            });
        });
      }
      return variants;
    },
    ...mapGetters(["getSpecies", "isLoading"]),
  },
  created() {
    this.dymo = new Dymo();
    this.dymo.getPrinters();
  },
  methods: {},
};
</script>

<style lang="scss">
@media print {
  #app.bg-dark.text-light,
  html,
  body {
    color: black !important;
    background-color: white !important;
  }
  #shipment-print {
    h1,
    .nav,
    .cost,
    .actions,
    .btn {
      display: none;
    }
    th,
    td {
      border: 2px solid black;
      vertical-align: middle;
      padding: 5px;
    }
  }
}
</style>

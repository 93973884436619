<template lang="pug">
#home
  b-row
    b-col(cols="12" md="4")
      b-card(title="Tags" bg-variant="dark" text-variant="white" border-variant="light")
        b-btn(size="lg" to="tag/associate" variant="success") Associate Tag
      b-card(title="Orders" bg-variant="dark" text-variant="white" border-variant="light")
        b-btn(size="lg" to="order/0" variant="success") Add New
      b-card(title="Deaths" bg-variant="dark" text-variant="white" border-variant="light")
        b-btn(size="lg" to="death/0" variant="success") Record Death
    b-col(cols="12" md="4")
      b-card(title="YouTube Subscribers" bg-variant="dark" text-variant="white" border-variant="light")
        MetadataDisplay(metadataKey="youtube_subscribers")
    b-col(cols="12" md="4")
      b-card(title="Entity Totals" bg-variant="dark" text-variant="white" border-variant="light")
        MetadataDisplay(metadataKey="counts")


</template>

<script>
import MetadataDisplay from "@/components/MetadataDisplay.vue";
export default {
  name: "HomeView",
  components: {
    MetadataDisplay,
  },
};
</script>

import {
  USER_PROFILE_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "@/store/actions/user";
import defineAbilityFor from "@/ability";

export default (store) => {
  const ability = store.getters.ability;

  // ability.update(store.state.rules);

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case USER_PROFILE_SUCCESS:
        ability.update(defineAbilityFor(mutation.payload));
        break;
      case USER_LOGOUT_SUCCESS:
        ability.update(defineAbilityFor(null));
    }
  });
};

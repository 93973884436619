<template lang="pug">
#region-edit(v-if="region")
  .mb-5
    h1 Edit {{ region.name }}
    b-form(@submit.prevent="saveRegion")
      b-form-group#name-group(label="Name: ", label-for="name")
        b-form-input#name(
          v-model="region.name",
          type="text",
          required,
          placeholder="Region Name"
        )
      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  REGION_LIST_REQUEST,
  REGION_EDIT_REQUEST,
} from "@/store/actions/region";

export default {
  name: "RegionEdit",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      region: null,
    };
  },
  computed: {
    ...mapGetters(["getRegions"]),
  },
  created() {
    this.$store.dispatch(REGION_LIST_REQUEST).then(() => {
      let toEdit = this.getRegions.find(
        (region) => region.id === parseInt(this.$route.params.regionId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          name: "",
        };
      }
      this.region = toEdit;
    });
  },
  methods: {
    saveRegion() {
      this.$store.dispatch(REGION_EDIT_REQUEST, this.region).then(() => {
        if (!this.$props.modal) {
          this.$router.push("/region/list");
        } else {
          this.$store.dispatch(REGION_LIST_REQUEST).then(() => {
            this.$parent.$parent.hide();
          });
        }
      });
    },
  },
};
</script>

<template lang="pug">
iframe#grafana(:src="host + '/grafana/d/deaths_dashboard/deaths?orgId=1'")
</template>
<script>
export default {
  name: "GrafanaView",
  data() {
    return {
      host: window.location.protocol + "//" + window.location.host,
    };
  },
};
</script>
<style lang="scss" scoped>
#grafana {
  width: 100%;
  height: 100%;
  border: none;
}
</style>

import axios from "axios";
import { API_BASE_URL } from "@/constants";

import {
  TAG_ASSOCIATE_REQUEST,
  TAG_PREVIEW_REQUEST,
  TAG_LIST_REQUEST,
  TAG_DETAILS_REQUEST,
  TAG_LOADING_START,
  TAG_LOADING_END,
} from "../actions/tag";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  state: {
    status: "init",
  },
  actions: {
    [TAG_ASSOCIATE_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(TAG_LOADING_START);
        axios
          .post(`${API_BASE_URL}/tag/associate`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Tag",
              body: `Tag ${data.tag_id} has been successfully associated.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(TAG_LOADING_END));
      }),
    [TAG_PREVIEW_REQUEST]: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        commit(TAG_LOADING_START);
        const { tag_id, clover_id_1, clover_id_2 } = payload;
        let url = `${API_BASE_URL}/tag/preview/${tag_id}/${clover_id_1},`;
        if (clover_id_2 && clover_id_2 !== "") {
          url += `,${clover_id_2}`;
        }
        axios
          .get(url)
          .then((resp) => {
            const data = resp.data;
            if (data.length > 0) {
              const { image } = data[0];
              resolve(image);
              return image;
            }
          })
          .catch((err) => reject(err))
          .finally(() => commit(TAG_LOADING_END));
      }),
    [TAG_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(TAG_LOADING_START);
        axios
          .get(`${API_BASE_URL}/tag/list`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(TAG_LOADING_END));
      }),
    [TAG_DETAILS_REQUEST]: ({ commit }, tag_id) =>
      new Promise((resolve, reject) => {
        commit(TAG_LOADING_START);
        axios
          .get(`${API_BASE_URL}/tag/${tag_id}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(TAG_LOADING_END));
      }),
  },
  mutations: {
    [TAG_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [TAG_LOADING_END]: (state) => {
      state.status = "loaded";
    },
  },
  getters: {
    tagStatus: (state) => state.status,
  },
};

export default module;

import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_EDIT_REQUEST,
  SUPPLIER_LOADING_START,
  SUPPLIER_LOADING_END,
} from "../actions/supplier";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getSuppliers: (state) => state.suppliers,
    getSupplierById: (state) => (id) => {
      return state.suppliers.find((supplier) => supplier.id === id);
    },
    supplierStatus: (state) => state.status,
  },
  state: {
    suppliers: [],
    status: "init",
  },
  actions: {
    [SUPPLIER_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(SUPPLIER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/supplier/`)
          .then((resp) => {
            const suppliers = resp.data;
            commit(SUPPLIER_LIST_SUCCESS, suppliers);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SUPPLIER_LOADING_END));
      }),
    [SUPPLIER_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(SUPPLIER_LOADING_START);
        axios
          .post(`${API_BASE_URL}/supplier/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Supplier",
              body: `Supplier ${data.name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SUPPLIER_LOADING_END));
      }),
  },
  mutations: {
    [SUPPLIER_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [SUPPLIER_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [SUPPLIER_LIST_SUCCESS]: (state, suppliers) => {
      Vue.set(state, "suppliers", suppliers);
    },
  },
};

export default module;

<template lang="pug">
v-select(
  :placeholder="placeholder",
  :value="value",
  :options="getUsers",
  @input="$emit('input', $event)",
  :reduce="(user) => user.id",
  label="name"
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import { USER_LIST_REQUEST } from "@/store/actions/user";
export default {
  name: "UserSelect",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Select User",
    },
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
  created() {
    this.$store.dispatch(USER_LIST_REQUEST);
  },
};
</script>

import { AbilityBuilder, Ability } from "@casl/ability";
import { UserPermissionsLevel } from "@/constants";

export default (user) => {
  const { can, build } = new AbilityBuilder(Ability);

  // cannot("read", "all");

  if (user) {
    can("read", "system");
    can("read", "spceies");
    can("read", "speciesSubType");
    can("read", "product");
    can("read", "location");
    can("read", "customer");
    can("read", "order");
    can("read", "supplier");
    can("read", "shipment");
    can("read", "region");
    can("read", "deaths");
    can("read", "data");
    can("associate", "tag");
    can("manage", "inventory");

    if (user.permission_level == UserPermissionsLevel.Admin) {
      can("manage", "all");
    }
  }
  return build().rules;
};

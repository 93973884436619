<template lang="pug">
#shipments-list
  .mb-1
    h1 Shipments
    b-button(to="./0", variant="success") Add New
  .row.mb-3
   .col-6
      label Supplier
      SupplierSelect(
        v-model="filters.supplier",
        placeholder="Select Supplier",
      )
  b-row
    b-col
      p
        b-button(@click="resetFilters") Reset Filters
      p
        b-pagination(v-model="filters.page", :total-rows="results.metadata.total", :per-page="filters.perPage")
      p {{ pageFirstRecord }} - {{ pageLastRecord }} of {{ results.metadata.total }}
  b-table#table(
    small
    stacked="lg" 
    :items="tableProvider",
    :fields="fields",
    responsive,
    striped,
    dark,
    :per-page="filters.perPage"
    :current-page="filters.page"
  )
    template(#cell(actions)="data")
      b-btn-group  
        b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
        b-button(@click="deleteShipment(data.item)", variant="danger" size="sm") Delete
</template>
<script>
import { mapGetters } from "vuex";
import {
  SHIPMENT_LIST_REQUEST,
  SHIPMENT_SEARCH_REQUEST,
  SHIPMENT_DELETE_REQUEST,
} from "@/store/actions/shipment";
import { formatDate } from "@/utils";
import SupplierSelect from "../../components/select/SupplierSelect.vue";

const defaultFilters = () => ({
  searchQuery: null,
  supplier: null,
  page: 1,
  perPage: 25,
});

export default {
  name: "ShipmentList",
  components: {
    SupplierSelect,
  },
  data() {
    return {
      results: {
        data: [],
        metadata: {
          total: 0,
          page: 1,
          pages: 1,
          count: 0,
        },
      },
      filters: defaultFilters(),
      fields: [
        "id",
        {
          key: "supplier",
          formatter: (val) => {
            return val.name;
          },
        },
        {
          key: "arrival_date",
          formatter: formatDate,
        },
        {
          key: "qt_date",
          formatter: formatDate,
        },
        "item_count",
        "actions",
      ],
    };
  },
  computed: {
    searchResults() {
      return this.getShipments.filter((shipment) => {
        return this.filterSupplier
          ? shipment.supplier.id == this.filterSupplier
          : true;
      });
    },
    pageFirstRecord() {
      return this.filters.perPage * (this.filters.page - 1) + 1;
    },
    pageLastRecord() {
      return Math.min(
        this.filters.perPage * this.filters.page,
        this.results.metadata.total
      );
    },
    ...mapGetters([
      "getShipments",
      "getSupplierById",
      "shipmentStatus",
      "supplierStatus",
    ]),
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        const query = {};
        for (const key in this.filters) {
          if (this.filters[key] !== null) {
            query[key] = this.filters[key];
          }
        }
        //only update if different than existing query
        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.push({ query });
        }
        this.$root.$emit("bv::refresh::table", "table");
      },
    },
  },
  created() {
    // set filters from query params
    for (const key in this.$route.query) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.filters.hasOwnProperty(key)) {
        if (key !== "searchQuery" && this.$route.query[key] !== "") {
          this.filters[key] = parseInt(this.$route.query[key]);
        } else {
          this.filters[key] = this.$route.query[key];
        }
      }
    }
    this.search();
  },
  methods: {
    search() {
      return this.$store
        .dispatch(SHIPMENT_SEARCH_REQUEST, this.filters)
        .then((resp) => {
          this.results = resp;
          return resp;
        });
    },
    tableProvider(ctx, callback) {
      this.search().then(() => {
        callback(this.results.data);
      });
    },
    resetFilters() {
      this.filters = defaultFilters();
    },
    deleteShipment(customer) {
      if (
        confirm(
          "Are you sure you want to delete this shipment? All shipment items will be deleted."
        )
      ) {
        this.$store.dispatch(SHIPMENT_DELETE_REQUEST, customer).then(() => {
          this.$store.dispatch(SHIPMENT_LIST_REQUEST);
        });
      }
    },
  },
};
</script>

export const SHIPMENT_LIST_REQUEST = "SHIPMENT_LIST_REQUEST";
export const SHIPMENT_SEARCH_REQUEST = "SHIPMENT_SEARCH_REQUEST";
export const SHIPMENT_LIST_SUCCESS = "SHIPMENT_LIST_SUCCESS";
export const SHIPMENT_LIST_BY_VARIANT_REQUEST =
  "SHIPMENT_LIST_BY_VARIANT_REQUEST";
export const SHIPMENT_LIST_BY_VARIANT_SUCCESS =
  "SHIPMENT_LIST_BY_VARIANT_SUCCESS";
export const SHIPMENT_DETAILS_REQUEST = "SHIPMENT_DETAILS_REQUEST";
export const SHIPMENT_EDIT_REQUEST = "SHIPMENT_EDIT_REQUEST";
export const SHIPMENT_DELETE_REQUEST = "SHIPMENT_DELETE_REQUEST";
export const SHIPMENT_ITEMS_LIST_REQUEST = "SHIPMENT_ITEM_LIST_REQUEST";
export const SHIPMENT_ITEMS_LIST_SUCCESS = "SHIPMENT_ITEM_LIST_SUCCESS";
export const SHIPMENT_ITEMS_EDIT_REQUEST = "SHIPMENT_ITEM_EDIT_REQUEST";
export const SHIPMENT_LOADING_START = "SHIPMENT_LOADING_START";
export const SHIPMENT_LOADING_END = "SHIPMENT_LOADING_END";

<template lang="pug">
#suppliers-list(v-if="supplier")
  .mb-5
    h1 Edit {{ supplier.name }}
  b-form(@submit.prevent="saveSupplier")
    b-form-group#name-group(label="Name: ", label-for="name")
      b-form-input#name(
        v-model="supplier.name",
        type="text",
        required,
        placeholder="Supplier Name"
      )
    b-form-group#email-group(label="Email: ", label-for="email")
      b-form-input#email(
        v-model="supplier.email_address",
        type="email",
        placeholder="Supplier Email"
      )
    b-form-group#phone-group(label="Phone: ", label-for="phone")
      b-form-input#phone(
        v-model="supplier.phone_number",
        type="tel",
        placeholder="Supplier Phone"
      )
    b-form-group#box-fee-group(label="Box Fee: ", label-for="box-fee")
      b-form-input#box-fee(
        v-model="supplier.box_fee",
        type="number",
        placeholder="Supplier Box Fee",
        prepend="$",
        step="0.01",
        min="0"
      )
    b-form-group#shipping-fee-group(
      label="Shipping Fee: ",
      label-for="shipping-fee"
    )
      b-form-input#shipping-fee(
        v-model="supplier.shipping_fee",
        type="number",
        placeholder="Supplier Shipping Fee",
        prepend="$",
        step="0.01",
        min="0"
      )
    b-form-group#contact-pref-group(label="Contact Preference")
      EnumSelect(
        v-model="supplier.contact_preference",
        :enum="ContactPreference",
        placeholder="Select Contact Preference"
      )
    b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_EDIT_REQUEST,
} from "@/store/actions/supplier";
import EnumSelect from "@/components/select/EnumSelect.vue";
import { ContactPreference } from "@/constants";

export default {
  name: "SupplierEdit",
  components: {
    EnumSelect,
  },
  data() {
    return {
      supplier: null,
      ContactPreference,
    };
  },
  computed: {
    ...mapGetters(["getSuppliers"]),
  },
  created() {
    this.$store.dispatch(SUPPLIER_LIST_REQUEST).then(() => {
      let toEdit = this.getSuppliers.find(
        (supplier) => supplier.id === parseInt(this.$route.params.supplierId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          name: "",
          email_address: "",
          phone_number: "",
          contact_preference: null,
          box_fee: 0,
          shipping_fee: 0,
        };
      }
      this.supplier = toEdit;
    });
  },
  methods: {
    saveSupplier() {
      this.$store.dispatch(SUPPLIER_EDIT_REQUEST, this.supplier).then(() => {
        this.$router.push("/supplier/list");
      });
    },
  },
};
</script>

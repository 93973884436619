<template lang="pug">
#locations-death(v-if="location")
  .mb-5
    h1 Record Death - {{ location.name }}
    b-form(@submit.prevent="saveDeath")
      b-form-group#vadriant-group(label="Species Variant")
        v-select(
          :options="variantOptions"
          v-model="variant"
          label="label"
          )
      b-form-group#shipment-group(label="Shipment" v-if="variant && variant.from_shipment")
        v-select(
          :options="shipmentOptions"
          v-model="shipment"
          label="label"
          )
      b-form-group#quantity-group(label="Quantity")
        b-form-input(type="number" v-model="quantity")
      b-button(type="submit" variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import { SPECIES_VARIANT_LIST_BY_LOCATION_REQUEST } from "@/store/actions/species";
import { SHIPMENT_LIST_BY_VARIANT_REQUEST } from "@/store/actions/shipment";
import { LOCATION_LIST_REQUEST } from "@/store/actions/location";
import { DEATH_EDIT_REQUEST } from "@/store/actions/death";
import SystemSelect from "@/components/select/SystemSelect.vue";
import { DateTime } from "luxon";

export default {
  name: "LocationDeath",
  components: {
    SystemSelect,
  },
  data() {
    return {
      location: null,
      variants: [],
      shipments: [],
      variant: null,
      shipment: null,
      timestamp: null,
      quantity: 1,
    };
  },
  computed: {
    variantOptions() {
      const options = [];
      if (this.variants.length > 0) {
        this.variants.forEach((variant) => {
          options.push({
            label: `${variant.common_name} (${variant.scientific_name})`,
            id: variant.id,
            from_shipment: variant.from_shipment || false,
          });
        });
      }
      return options;
    },
    shipmentOptions() {
      const options = [];
      if (this.shipments.length > 0) {
        this.shipments.forEach((shipment) => {
          options.push({
            label: `${new DateTime(shipment.arrival_date).toISODate()} (${
              shipment.supplier_name
            })`,
            id: shipment.shipment_item_id,
          });
        });
      }
      return options;
    },
    ...mapGetters(["getLocations"]),
  },
  watch: {
    variant(newVal) {
      if (newVal.from_shipment) {
        this.$store
          .dispatch(SHIPMENT_LIST_BY_VARIANT_REQUEST, this.variant.id)
          .then(({ data }) => {
            const shipments = data;
            this.shipments = shipments;
          });
      } else {
        this.shipment = null;
      }
    },
  },
  created() {
    this.$store.dispatch(LOCATION_LIST_REQUEST).then(() => {
      this.location = this.getLocations.find(
        (location) => location.id === parseInt(this.$route.params.locationId)
      );
      this.$store
        .dispatch(SPECIES_VARIANT_LIST_BY_LOCATION_REQUEST, this.location.id)
        .then(({ data }) => {
          const { variants } = data;
          this.variants = variants;
        });
    });
  },
  methods: {
    saveDeath() {
      this.$store
        .dispatch(DEATH_EDIT_REQUEST, {
          id: null,
          location: this.location.id,
          quantity: this.quantity,
          variant: this.variant.id,
          shipment_item: this.shipment.id,
        })
        .then(() => {
          this.$router.push("/location/list");
        });
    },
  },
};
</script>

<template lang="pug">
b-table(small stacked="lg" :items="deaths", :fields="fields", responsive, striped, dark)
  template(#cell(shipment)="data")
    b-link(v-if="data.item.shipment_item" :to="`/shipment/${data.item.shipment_item.shipment.id}`") {{data.item.shipment_item.shipment.supplier.name}} ({{formatDate(data.item.shipment_item.shipment.arrival_date)}})
  template(#cell(actions)="data")
    b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
    b-button(@click="deleteDeath(data.item)", variant="danger" size="sm") Delete
</template>
<script>
import { mapGetters } from "vuex";
import { DEATH_DELETE_REQUEST } from "@/store/actions/death";
import { DateTime } from "luxon";
import { formatDate } from "@/utils";
import eventBus from "@/eventbus";

export default {
  name: "DeathListTable",
  props: {
    deaths: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formatDate,
      fields: [
        {
          key: "timestamp",
          formatter: formatDate,
        },
        {
          key: "variant",
          formatter: (val) =>
            `${val.species.common_name} (${val.species.scientific_name})`,
        },
        "quantity",
        {
          key: "location",
          formatter: (val) => (val ? `${val.name} (${val.system.name})` : ""),
        },
        "shipment",
        {
          key: "shipment",
          formatter: (val, key, item) =>
            item.shipment_item
              ? `${item.shipment_item.shipment.supplier.name} (${formatDate(
                  item.shipment_item.shipment.arrival_date
                )})`
              : "",
        },
        {
          key: "shipment_item",
          label: "Week",
          formatter(val, key, item) {
            if (!val) return "N/A";
            const arrivalDate = DateTime.fromISO(val.shipment.arrival_date);
            const deathDate = DateTime.fromISO(item.timestamp);
            return Math.floor(
              deathDate.diff(arrivalDate, "weeks").toObject().weeks
            );
          },
        },
        "actions",
      ],
    };
  },
  computed: {
    ...mapGetters(["getDeaths"]),
  },
  methods: {
    recordDeath() {
      eventBus.$emit("record-death", null, null, null);
    },
    deleteDeath(death) {
      if (confirm("Are you sure you want to delete this death?")) {
        this.$store.dispatch(DEATH_DELETE_REQUEST, death).then(() => {
          eventBus.$emit("death-deleted", null, null, null);
        });
      }
    },
  },
};
</script>

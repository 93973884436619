<template lang="pug">
#suppliers-list
  .mb-1
    h1 Suppliers
    b-button(to="./new", variant="success") Add New
  b-table(small stacked="lg" :items="getSuppliers", :fields="fields", responsive, striped, dark)
    template(#cell(actions)="data")
      b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
</template>
<script>
import { mapGetters } from "vuex";
import { SUPPLIER_LIST_REQUEST } from "@/store/actions/supplier";

export default {
  name: "SupplierList",
  data() {
    return {
      fields: [
        "name",
        "email_address",
        "phone_number",
        {
          key: "box_fee",
          formatter: (val) => "$" + val.toString(),
        },
        {
          key: "shipping_fee",
          formatter: (val) => "$" + val.toString(),
        },
        "actions",
      ],
    };
  },
  computed: {
    ...mapGetters(["getSuppliers"]),
  },
  created() {
    this.$store.dispatch(SUPPLIER_LIST_REQUEST);
  },
};
</script>

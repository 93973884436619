<template lang="pug">
#shipment-print
  h1 Print Shipment Labels
  b-button(@click="printAll", variant="success") Print All
  b-table(small stacked="lg" :items="shipmentItems", :fields="fields", dark, v-if="!isLoading")
    template(#cell(clover_id)="data") {{ data.item.species_variant.clover_item.clover_id }}
    template(#cell(barcode)="data")
      DymoLabel(
        :dymo="dymo",
        :label_xml="LABEL_XML",
        :clover_id="data.item.species_variant.clover_item.clover_id",
        :scientific_name="data.item.species_variant.species.scientific_name",
        :common_name="data.item.species_variant.species.common_name",
        :description="data.item.species_variant.description",
        :qt_date="shipment.qt_date",
        :quantity="data.item.num_received",
        :price="data.item.price"
      )
    template(#cell(species_variant)="data") {{ data.item.species_variant.species.common_name }} ({{ data.item.species_variant.species.scientific_name }})
    //- template(#cell(qt_date)="data") {{ shipment.qt_date }}
</template>

<script>
import { mapGetters } from "vuex";
import LABEL_XML from "!raw-loader!@/assets/xml/label1.label";
import Dymo from "dymojs";

import {
  SHIPMENT_DETAILS_REQUEST,
  SHIPMENT_ITEMS_LIST_REQUEST,
} from "@/store/actions/shipment";
import DymoLabel from "@/components/DymoLabel.vue";
import { formatDate } from "@/utils";

export default {
  name: "ShipmentItemsPrint",
  components: {
    DymoLabel,
  },
  data() {
    return {
      shipment: null,
      dymo: null,
      LABEL_XML,
      shipmentItems: [],
      fields: [
        {
          key: "barcode",
          tdClass: "barcode",
          thClass: "barcode",
          thStyle: {
            width: "1%",
          },
        },
        {
          key: "clover_id",
          label: "Clover ID",
          tdClass: "clover_id",
          thClass: "clover_id",
          thStyle: {
            width: "1%",
          },
        },
        {
          key: "species_variant",
          tdClass: "species_variant",
          thClass: "species_variant",
        },
        {
          key: "price",
          tdClass: "price",
          thClass: "price",
          thStyle: {
            width: "10%",
          },
          formatter: (val) => `$${val.toFixed(2)}`,
        },
        {
          key: "qt_date",
          formatter: formatDate,
        },
        {
          key: "num_received",
          tdClass: "num_received",
          thClass: "num_received",
          thStyle: {
            width: "15%",
          },
          formatter: (val) => `${val} Received`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  created() {
    this.dymo = new Dymo();
    this.dymo.getPrinters();
    this.$store
      .dispatch(SHIPMENT_DETAILS_REQUEST, this.$route.params.shipmentId)
      .then((shipment) => {
        this.shipment = shipment;
      });
    this.$store
      .dispatch(SHIPMENT_ITEMS_LIST_REQUEST, this.$route.params.shipmentId)
      .then(({ data }) => {
        data.forEach((item) => this.shipmentItems.push(item));
      });
  },
  methods: {
    printAll() {
      document.querySelectorAll(".label-preview").forEach((label, index) => {
        setTimeout(() => {
          label.click();
        }, index * 500);
      });
    },
  },
};
</script>

<style lang="scss">
@media print {
  #app.bg-dark.text-light,
  html,
  body {
    color: black !important;
    background-color: white !important;
  }
  #shipment-print {
    h1,
    .nav,
    .cost,
    .actions,
    .btn {
      display: none;
    }
    th,
    td {
      border: 2px solid black;
      vertical-align: middle;
      padding: 5px;
    }
  }
}
</style>

<template lang="pug">
#products-list(v-if="product")
  .mb-5
    h1 Edit Product
    b-form(@submit.prevent="saveProduct")
      template
        b-form-group#species-group(label="Species: ", label-for="Species")
          SpeciesSelect(v-model="product.species")
        b-form-group#clover-id-group(
          label="Clover ID: ",
          label-for="clover-id"
        )
          b-form-input#clover-id(
            v-model="product.clover_id",
            type="text",
            placeholder="Product Clover ID"
          )
        b-form-group#diet-group(label="Description: ", label-for="description")
          b-form-input#description(
            v-model="product.description",
            type="text",
            placeholder="Product Description (small, red, etc)"
          )
        b-form-group#notes-group(label="Notes: ", label-for="notes")
          b-form-textarea#notes(
            v-model="product.notes",
            type="text",
            placeholder="Product Notes"
          )
        b-form-group#location-group(label="Locations: ", label-for="locations")
          LocationSelect(v-model="product.locations", :multiple="true")
        b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_EDIT_REQUEST,
} from "@/store/actions/product";
import SpeciesSelect from "@/components/select/SpeciesSelect.vue";
import LocationSelect from "@/components/select/LocationSelect.vue";

export default {
  name: "ProductEdit",
  components: {
    SpeciesSelect,
    LocationSelect,
  },
  data() {
    return {
      product: null,
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
  },
  created() {
    if (!this.getProducts.length) {
      this.$store.dispatch(PRODUCT_LIST_REQUEST).then(() => {
        this.setProduct();
      });
    } else {
      this.setProduct();
    }
  },
  methods: {
    setProduct() {
      let toEdit = this.getProducts.find(
        (product) => product.id === parseInt(this.$route.params.productId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          species: null,
          clover_id: null,
          notes: null,
          description: null,
          locations: [],
        };
      } else {
        if (toEdit.locations.length) {
          toEdit.locations = toEdit.locations.map(
            (location) => location.location.id
          );
        }
        toEdit.species = toEdit.species.id;
      }
      this.product = toEdit;
    },
    saveProduct() {
      this.$store.dispatch(PRODUCT_EDIT_REQUEST, this.product).then(() => {
        this.$router.push("/product/list");
      });
    },
  },
};
</script>

<template lang="pug">
#locations-summary(v-if="location")
  h1 Location: {{ location.system.name }} / {{ location.name }}
  h2 Species:
  b-row
    b-col(cols="12", sm="4" v-for="svl in location.variants" :key="svl.id" )
      SpeciesVariantCard(:variant="svl.species_variant" :in_stock="svl.in_stock")

    
</template>

<script>
import { LOCATION_DETAILS_REQUEST } from "@/store/actions/location";
import SpeciesVariantCard from "@/components/card/SpeciesVariantCard.vue";

export default {
  name: "LocationSummary",
  components: {
    SpeciesVariantCard,
  },
  data() {
    return {
      location: null,
    };
  },
  created() {
    this.$store
      .dispatch(LOCATION_DETAILS_REQUEST, this.$route.params.locationId)
      .then((location) => {
        this.location = location;
      });
  },
  methods: {},
};
</script>

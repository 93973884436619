<template lang="pug">
b-card.mb-2(
  text-variant="dark",
  :footer-bg-variant="in_stock ? 'success' : ''",q
  :header="headerText",
  :footer="footerText",
  :title="variant.species.scientific_name"
  :sub-title="variant.species.common_name"
  :style="{ minHeight: '10rem', maxWidth: '20rem' }"
)
  b-card-body
    //- pre {{ variant }}π∂
    table
      tbody
        tr   A  
          td
            | Environment:
          td
            | {{ enumValueToKey(EnvironmentType, variant.species.environment_type) }}
        
</template>

<script>
import { EnvironmentType, SpeciesType } from "@/constants";

export default {
  name: "SpeciesVariantCard",
  props: {
    variant: {
      type: Object,
      required: true,
    },
    in_stock: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      EnvironmentType,
      SpeciesType,
    };
  },
  computed: {
    headerText() {
      const { species } = this.variant;
      const environment_type = this.enumValueToKey(
        EnvironmentType,
        species.environment_type
      );
      const species_type = this.enumValueToKey(
        SpeciesType,
        species.species_type
      );
      return `${environment_type} ${species_type}`;
    },
    footerText() {
      const { clover_item } = this.variant;
      return this.in_stock
        ? `Available - $${clover_item.price}`
        : "Not Available";
    },
  },
  methods: {
    enumValueToKey(enumObj, value) {
      return Object.keys(enumObj).find((key) => enumObj[key] === value);
    },
  },
};
</script>

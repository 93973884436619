export const SPECIES_LIST_REQUEST = "SPECIES_LIST_REQUEST";
export const SPECIES_LIST_SUCCESS = "SPECIES_LIST_SUCCESS";
export const SPECIES_DETAILS_REQUEST = "SPECIES_DETAILS_REQUEST";
export const SPECIES_DETAILS_SUCCESS = "SPECIES_DETAILS_SUCCESS";
export const SPECIES_SEARCH_REQUEST = "SPECIES_SEARCH_REQUEST";
export const SPECIES_SEARCH_SUCCESS = "SPECIES_SEARCH_SUCCESS";
export const SPECIES_VARIANT_LIST_BY_LOCATION_REQUEST =
  "SPECIES_VARIANT_LIST_BY_LOCATION_REQUEST";
export const SPECIES_VARIANT_LIST_BY_LOCATION_SUCCESS =
  "SPECIES_VARIANT_LIST_BY_LOCATION_SUCCESS";
export const SPECIES_VARIANT_LIST_REQUEST = "SPECIES_VARIANT_LIST_REQUEST";
export const SPECIES_VARIANT_LIST_SUCCESS = "SPECIES_VARIANT_LIST_SUCCESS";
export const SPECIES_EDIT_REQUEST = "SPECIES_EDIT_REQUEST";

export const SPECIES_DELETE_REQUEST = "SPECIES_DELETE_REQUEST";
export const SPECIES_DELETE_SUCCESS = "SPECIES_DELETE_SUCCESS";
export const SPECIES_VARIANT_DELETE_REQUEST = "SPECIES_VARIANT_DELETE_REQUEST";

export const SPECIES_SUBTYPE_LIST_REQUEST = "SPECIES_SUBTYPE_LIST_REQUEST";
export const SPECIES_SUBTYPE_LIST_SUCCESS = "SPECIES_SUBTYPE_LIST_SUCCESS";
export const SPECIES_SUBTYPE_EDIT_REQUEST = "SPECIES_SUBTYPE_EDIT_REQUEST";

export const SPECIES_VARIANT_LOCATION_ADD = "SPECIES_VARIANT_LOCATION_ADD";
export const SPECIES_VARIANT_LOCATION_DELETE =
  "SPECIES_VARIANT_LOCATION_DELETE";
export const SPECIES_VARIANT_LOCATION_STOCK = "SPECIES_VARIANT_LOCATION_STOCK";

export const SPECIES_LIVESTOCK_LIST_REQUEST = "SPECIES_LIVESTOCK_LIST_REQUEST";

export const SPECIES_LOADING_START = "SPECIES_LOADING_START";
export const SPECIES_LOADING_END = "SPECIES_LOADING_END";

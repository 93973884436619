<template lang="pug">
#variant-print
  h1 Print Species Variant Stickers
  CloverItemSelect(
    v-model="item"
    :returnObject="true"
  )
  DymoLabel(
    v-if="item"
    :dymo="dymo",
    :label_xml="LABEL_XML",
    :scientific_name="variant.species.scientific_name",
    :common_name="variant.species.common_name",
    :description="variant.description",
    :collection_location="variant.collection_location",
    :price="item.price",
  )
</template>

<script>
import { mapGetters } from "vuex";
import LABEL_XML from "!raw-loader!@/assets/xml/label1.label";
import Dymo from "dymojs";

import DymoLabel from "@/components/DymoLabel.vue";
import CloverItemSelect from "@/components/select/CloverItemSelect.vue";

export default {
  name: "ShipmentItemsPrint",
  components: {
    DymoLabel,
    CloverItemSelect,
  },
  data() {
    return {
      item: null,
      dymo: null,
      LABEL_XML,
    };
  },
  computed: {
    variant() {
      if (this.item && this.item.variants) {
        return this.item.variants[0];
      }
      return null;
    },
    ...mapGetters(["isLoading"]),
  },
  created() {
    this.dymo = new Dymo();
    this.dymo.getPrinters();
  },
  methods: {
    print() {
      this.dymo.printLabel(this.LABEL_XML);
    },
  },
};
</script>

<style lang="scss">
@media print {
  #app.bg-dark.text-light,
  html,
  body {
    color: black !important;
    background-color: white !important;
  }
  #shipment-print {
    h1,
    .nav,
    .cost,
    .actions,
    .btn {
      display: none;
    }
    th,
    td {
      border: 2px solid black;
      vertical-align: middle;
      padding: 5px;
    }
  }
}
</style>

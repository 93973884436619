<template lang="pug">
#order-edit(v-if="order")
  .mb-5
    h1 Edit Order
      span(v-if="order.id") - {{ order.id }}
    b-form(@submit.prevent="saveOrder")
      b-row 
        b-col(cols="12" sm="6")
          b-form-group#customer-group(label="Customer: ", label-for="customer")
            CustomerSelect(v-model="order.customer" required)
          b-form-group#user-group(label="Assigned Employee: ", label-for="user")
            UserSelect(v-model="order.user" required)
          b-form-group#order-date-group(
            label="Order Date: ",
            label-for="order-date"
            
          )
            b-form-datepicker#order-date(v-model="order.order_date" required)
          .accordion(role="tablist" id="order-items")
            b-card.mb-2(
              no-body
              bg-variant="secondary"
              text-variant="white"
              v-for="(item, index) in order.items"
              :key="index")
              template(#header)
                div.d-flex.justify-content-between
                  div {{ item.quantity }}x 
                    template(v-if="item.variant")
                      span {{ getVariantById(item.variant) | formatVariant }}
                    template(v-else-if="item.clover_item")
                      span {{ item.clover_item.name }}
                  EnumSelect.ml-auto.w-25(
                        v-model="item.status",
                        :enum="OrderItemStatus",
                        placeholder="Select Status",
                      )
                  b-button-group(size="sm")
                    b-button(variant="primary" v-b-toggle="`item-${index}`")
                      b-icon(icon="chevron-down")
                      b-icon(icon="chevron-up")
              b-collapse.p-2(
                :id="`item-${index}`"
                accordion="order-items"
              )
                b-form-group#type-group(label="Type: ", label-for="type")
                  b-input-group
                    EnumButtonGroup(
                      v-model="item.freeform_type"
                      :enum="FreeformType"
                      :required="true"
                      ).my-2
                  
                b-form-group#variant-group(
                  v-if="item.freeform_type == 1 || item.freeform_type == 2"
                  label="Variant: ", 
                  label-for="variant")
                  SpeciesVariantSelect(v-model="item.variant" :required="(item.freeform == null  || item.freeform.length === 0) && item.clover_item == null")
                b-form-group#clover-item-group(
                  v-if="item.freeform_type == 3"
                  label="Clover Item: ", 
                  label-for="clover-item")
                  CloverItemSelect(
                    v-model="item.clover_item",
                    placeholder="Clover Item",
                    :livestock-filter="'none'"
                    :required="(item.freeform == null  || item.freeform.length === 0) && item.variant == null"
                  )
                b-form-group#freeform-group(
                    label="Custom Text/Notes: ", 
                    label-for="freeform"
                  )
                  b-form-input(v-model="item.freeform")
                b-form-group#quantity-group(
                    label="Quantity: ", 
                    label-for="quantity"
                  )
                  b-form-input(v-model="item.quantity", type="number")
                b-form-group#shipment-group(
                    v-if="item.variant",
                    label="Shipment: ", 
                    label-for="shipment"
                  )
                  ShipmentByVariantSelect(
                    v-model="item.shipment_item",
                    :variant="item.variant"
                  )
                b-button(
                  variant="danger",
                  @click="removeItem(item, index)"
                ) Remove

        
      b-row
        b-col
          b-btn.mb-2(variant="info", size="sm", @click="addOrderItem") Add Item
      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  ORDER_DETAILS_REQUEST,
  ORDER_EDIT_REQUEST,
  ORDER_ITEM_DELETE_REQUEST,
} from "@/store/actions/order";
import SpeciesVariantSelect from "@/components/select/SpeciesVariantSelect.vue";
import ShipmentByVariantSelect from "@/components/select/ShipmentByVariantSelect.vue";
import CloverItemSelect from "@/components/select/CloverItemSelect.vue";
import CustomerSelect from "@/components/select/CustomerSelect.vue";
import UserSelect from "@/components/select/UserSelect.vue";
import { OrderItemStatus, FreeformType } from "@/constants";
import EnumSelect from "@/components/select/EnumSelect.vue";
import EnumButtonGroup from "@/components/select/EnumButtonGroup.vue";
import { DateTime } from "luxon";
import eventBus from "@/eventbus";
import { formatVariant } from "@/utils";

export default {
  name: "OrderEdit",
  filters: {
    formatVariant,
  },
  components: {
    UserSelect,
    CustomerSelect,
    SpeciesVariantSelect,
    ShipmentByVariantSelect,
    CloverItemSelect,
    EnumSelect,
    EnumButtonGroup,
  },
  data() {
    return {
      order: null,
      itemFields: [
        {
          key: "variant",
          thStyle: {
            width: "12.5%",
          },
        },
        {
          key: "clover_item",
          thStyle: {
            width: "12.5%",
          },
        },
        {
          key: "freeform",
          thStyle: {
            width: "15%",
          },
        },
        {
          key: "quantity",
          thStyle: {
            width: "5%",
          },
        },
        {
          key: "status",
          thStyle: {
            width: "10%",
          },
          tdClass(value, key, item) {
            if (item.id) {
              return "table-cell";
            } else {
              return "d-none";
            }
          },
        },
        {
          key: "shipment",
          thStyle: {
            width: "15%",
          },
          tdClass(value, key, item) {
            if (item.variant) {
              return "table-cell";
            } else {
              return "d-none";
            }
          },
        },
        "actions",
      ],
      OrderItemStatus,
      FreeformType,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentUser",
      "getCustomers",
      "getVariants",
      "getVariantById",
    ]),
  },
  watch: {
    "order.customer": function (value) {
      if (value) {
        const customer = this.getCustomers.find((c) => c.id === value);
        if (customer.employee) {
          this.order.user = customer.employee;
        }
      }
    },
  },
  created() {
    this.$store
      .dispatch(ORDER_DETAILS_REQUEST, this.$route.params.orderId)
      .then((order) => {
        let toEdit = order;
        if (!toEdit) {
          toEdit = {
            id: null,
            customer: null,
            order_date: DateTime.now().toISODate(),
            status: null,
            notes: "",
            user: this.getCurrentUser.id,
            items: [
              {
                id: null,
                variant: null,
                freeform: null,
                freeform_type: null,
                status: OrderItemStatus.Open,
                quantity: 1,
                shipment_item: null,
                clover_item: null,
              },
            ],
          };
        }
        this.order = toEdit;
        eventBus.$on("customer-saved", (customer) => {
          this.order.customer = customer.id;
        });
      });
  },
  methods: {
    saveOrder() {
      this.$store.dispatch(ORDER_EDIT_REQUEST, this.order).then(() => {
        this.$router.push("/order/list");
      });
    },
    addOrderItem() {
      this.order.items.push({
        id: null,
        variant: null,
        freeform: null,
        freeform_type: null,
        quantity: 1,
        status: OrderItemStatus.Open,
        shipment_item: null,
        clover_item: null,
      });
    },
    removeItem(item, index) {
      if (!item.id) {
        this.order.items.splice(index, 1);
        return;
      }
      if (confirm("Are you sure you want to delete this order item?")) {
        this.$store.dispatch(ORDER_ITEM_DELETE_REQUEST, item).then(() => {
          this.order.items.splice(index, 1);
        });
      }
    },
  },
};
</script>

<template lang="pug">
#app.bg-dark.text-light
  NavigationBar
  Messages
  router-view.container-fluid
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import Messages from "@/components/Messages.vue";
import NProgress from "nprogress";
import { mapGetters } from "vuex";
import { USER_LOGIN_SUCCESS, USER_PROFILE_REQUEST } from "@/store/actions/user";

export default {
  name: "App",
  components: {
    NavigationBar,
    Messages,
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        NProgress.start();
        window.progressInterval = setInterval(() => {
          NProgress.inc();
        }, 250);
      } else {
        NProgress.done();
        clearInterval(window.progressInterval);
      }
    },
  },
  created() {
    const token = localStorage.getItem("user-token");
    if (token) {
      this.$store.commit(USER_LOGIN_SUCCESS, token);
      this.$store.dispatch(USER_PROFILE_REQUEST);
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;

  > .container-fluid {
    min-height: 100vh;
  }
}
</style>

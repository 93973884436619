import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  CLOVER_LIST_REQUEST,
  CLOVER_SEARCH_REQUEST,
  CLOVER_LIST_SUCCESS,
  CLOVER_SYNC_REQUEST,
  CLOVER_SEARCH_SUCCESS,
  CLOVER_LOADING_START,
  CLOVER_LOADING_END,
  CLOVER_SKU_LOOKUP_REQUEST,
  CLOVER_INVENTORY_UPDATE_REQEUST,
} from "../actions/clover";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getCloverItems: (state) => state.cloverItems,
    getCloverItemById: (state) => (id) =>
      state.cloverItems.find((c) => c.id === id),
    cloverStatus: (state) => state.status,
  },
  state: {
    cloverItems: [],
    status: "init",
  },
  actions: {
    [CLOVER_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(CLOVER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/clover/items`)
          .then((resp) => {
            const cloverItems = resp.data;
            commit(CLOVER_LIST_SUCCESS, cloverItems);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CLOVER_LOADING_END));
      }),
    [CLOVER_SEARCH_REQUEST]: ({ commit }, query) =>
      new Promise((resolve, reject) => {
        commit(CLOVER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/clover/items/search`, {
            params: query,
          })
          .then((resp) => {
            const cloverItems = resp.data;
            resolve(cloverItems);
            commit(CLOVER_SEARCH_SUCCESS, cloverItems.items);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CLOVER_LOADING_END));
      }),
    [CLOVER_SYNC_REQUEST]: ({ commit, dispatch }) =>
      new Promise((resolve, reject) => {
        commit(CLOVER_LOADING_START);
        axios
          .post(`${API_BASE_URL}/clover/items/sync`)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Clover",
              body: "Clover items has been successfully synced.",
              category: "success",
            });
            dispatch(CLOVER_LIST_REQUEST);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CLOVER_LOADING_END));
      }),
    [CLOVER_SKU_LOOKUP_REQUEST]: ({ commit }, sku) =>
      new Promise((resolve, reject) => {
        commit(CLOVER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/clover/items/by_sku/${sku}`)
          .then((resp) => {
            const cloverItem = resp.data;
            resolve(cloverItem);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CLOVER_LOADING_END));
      }),
    [CLOVER_INVENTORY_UPDATE_REQEUST]: ({ commit, dispatch }, payload) =>
      new Promise((resolve, reject) => {
        commit(CLOVER_LOADING_START);
        axios
          .post(`${API_BASE_URL}/clover/items/${payload.id}`, payload)
          .then((resp) => {
            const cloverItem = resp.data;
            dispatch(MESSAGE_ADD, {
              title: "Inventory",
              body: `Item ${payload.name} has been successfully updated.`,
              category: "success",
            });
            resolve(cloverItem);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CLOVER_LOADING_END));
      }),
  },
  mutations: {
    [CLOVER_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [CLOVER_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [CLOVER_LIST_SUCCESS]: (state, cloverItems) => {
      Vue.set(state, "cloverItems", cloverItems);
    },
    [CLOVER_SEARCH_SUCCESS]: (state, cloverItems) => {
      cloverItems.forEach((c) => {
        if (state.cloverItems.find((c) => c.id === c.id)) {
          return;
        } else {
          state.cloverItems.push(c);
        }
      });
    },
  },
};

export default module;

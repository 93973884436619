import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_DETAILS_REQUEST,
  LOCATION_EDIT_REQUEST,
  LOCATION_DELETE_REQUEST,
  LOCATION_LOADING_START,
  LOCATION_LOADING_END,
} from "../actions/location";

import { SYSTEM_LOCATION_POPULATE } from "../actions/system";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getLocations: (state) => state.locations,
    getLocationById: (state) => (id) => {
      return state.locations.find((location) => location.id === id);
    },
    locationStatus: (state) => state.status,
  },
  state: {
    locations: [],
    status: "init",
  },
  actions: {
    [LOCATION_LIST_REQUEST]: ({ commit }, params = {}) =>
      new Promise((resolve, reject) => {
        commit(LOCATION_LOADING_START);
        axios
          .get(`${API_BASE_URL}/location/`, {
            params,
          })
          .then((resp) => {
            const locations = resp.data;
            if (params.system) {
              commit(SYSTEM_LOCATION_POPULATE, {
                systemId: params.system,
                locations,
              });
            } else {
              commit(LOCATION_LIST_SUCCESS, locations);
            }
            resolve(locations);
          })
          .catch((err) => reject(err))
          .finally(() => commit(LOCATION_LOADING_END));
      }),
    [LOCATION_DETAILS_REQUEST]: ({ commit }, id) =>
      new Promise((resolve, reject) => {
        commit(LOCATION_LOADING_START);
        axios
          .get(`${API_BASE_URL}/location/${id}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(LOCATION_LOADING_END));
      }),
    [LOCATION_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(LOCATION_LOADING_START);
        axios
          .post(`${API_BASE_URL}/location/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Location",
              body: `Location ${data.name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(LOCATION_LOADING_END));
      }),
    [LOCATION_DELETE_REQUEST]: ({ commit, dispatch }, location) =>
      new Promise((resolve, reject) => {
        commit(LOCATION_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/location/${location.id}`, location)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Location",
              body: `Location ${location.name} has been successfully deleted.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(LOCATION_LOADING_END));
      }),
  },
  mutations: {
    [LOCATION_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [LOCATION_LOADING_END]: (state) => {
      state.status = "loaded";
    },

    [LOCATION_LIST_SUCCESS]: (state, locations) => {
      Vue.set(state, "locations", locations);
    },
  },
};

export default module;

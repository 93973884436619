<template lang="pug">
#location-list
  .mb-5
    h1 Locations
    b-button(to="./new", variant="success") Add New
  b-row.mb-3(v-for="system, systemIndex in getSystems" :key="system.id")
    b-col(cols="12")
      h2 {{system.name}}
        b-button.ml-2(size="sm" variant="primary" v-b-toggle="`system-${system.id}`"  v-b-tooltip.hover title="Toggle")
          b-icon(icon="chevron-down")
          b-icon(icon="chevron-up")
      b-collapse(:id="`system-${system.id}`")
        b-row
          b-col(cols="12" v-for="location in system.locations" :key="location.id")
            b-card(body-class="p-0").text-dark.mb-3
              template(#header)
                div.d-flex.justify-content-between
                  div {{location.name}}
                    b-badge.mx-2(v-if="location.is_qt") Quarantine
                  b-button-group(size="sm")
                    b-button(:to="`./${location.id}`") Edit
                    b-button(variant="info" @click="showAddModal(location.id)") Add Species
                    b-button(variant="primary" v-b-toggle="`location-${location.id}`" v-if="location.variants.length > 0")
                      b-icon(icon="chevron-down")
                      b-icon(icon="chevron-up")
              b-collapse(:id="`location-${location.id}`" :visible="true")
                b-card-body(v-if="location.variants.length > 0")
                  b-table(small :items="location.variants" :fields="fields")
                    template(#cell(in_stock)="data")
                      b-form-checkbox(
                          v-model="data.item.in_stock",
                          :value="true",
                          :unchecked-value="false"
                          switch
                          @change="updateSVLStock(data.item)"
                        )
                    template(#cell(variant)="data")
                      router-link(:to="`/species/${data.item.species_variant.species.id}`") {{data.item.species_variant.species.common_name}} {{data.item.species_variant.description}} ({{data.item.species_variant.species.scientific_name}})
                    template(#cell(actions)="data")
                      b-button-group(size="sm")
                        b-button(variant="warning" @click="recordDeath(location.id, data.item.species_variant.id, null)" title="Record Death") Death
                        b-button(variant="info" @click="showMoveModal(location, data.item.species_variant)") Move
                        b-button(variant="danger" @click="removeVariantFromLocation(data.item.species_variant.id, location, true)") Remove

  b-modal(
    id="add-species"
    ok-title="Save"
    :title="`Add Species Variant - ${locationToAddTo ? locationToAddTo.name : ''}`"
    @ok="addVariantToLocation"
    @hidden="resetModals"
    no-close-on-backdrop)
    SpeciesVariantSelect(v-model="variantToAddID")
  b-modal(
    id="move-species"
    ok-title="Save"
    @ok="moveVariantToLocation"
    @hidden="resetModals"
    no-close-on-backdrop)
    template(#modal-title) Move Species Variant - {{variantToAdd | friendlyVariant }}
    LocationSelect(v-model="locationToAddToID")
  RecordDeathModal
</template>
<script>
import { mapGetters } from "vuex";
import { SYSTEM_LIST_REQUEST } from "@/store/actions/system";
import {
  LOCATION_LIST_REQUEST,
  LOCATION_DELETE_REQUEST,
} from "@/store/actions/location";
import {
  SPECIES_VARIANT_LOCATION_ADD,
  SPECIES_VARIANT_LOCATION_DELETE,
  SPECIES_VARIANT_LOCATION_STOCK,
} from "@/store/actions/species";
import { EnvironmentType } from "@/constants";
import SpeciesVariantSelect from "@/components/select/SpeciesVariantSelect.vue";
import LocationSelect from "@/components/select/LocationSelect.vue";
import RecordDeathModal from "@/components/RecordDeathModal.vue";
import eventBus from "@/eventbus";

export default {
  name: "LocationList",
  components: {
    SpeciesVariantSelect,
    LocationSelect,
    RecordDeathModal,
  },
  filters: {
    environmentType(val) {
      return Object.keys(EnvironmentType).find(
        (key) => EnvironmentType[key] === val
      );
    },
    friendlyVariant(variant) {
      let toDisplay = "";
      if (variant.species.common_name) {
        toDisplay += `${variant.species.common_name}, `;
      }
      if (variant.description) {
        toDisplay += `${variant.description} `;
      }
      toDisplay += `(${variant.species.scientific_name})`;
      return toDisplay;
    },
  },
  data() {
    return {
      locationToRemoveFrom: null,
      locationToAddToID: null,
      variantToAddID: null,
      variantToAdd: null,
      fields: [
        "variant",
        "in_stock",
        {
          key: "actions",
          thStyle: { width: "1%" },
        },
      ],
    };
  },
  computed: {
    locationToAddTo() {
      return this.getLocations.find(
        (location) => location.id == this.locationToAddToID
      );
    },
    ...mapGetters(["getSystems", "getLocations"]),
  },
  created() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (!collapseId.startsWith("system-")) {
        return;
      }
      const systemId = parseInt(collapseId.split("-")[1]);
      if (isJustShown) {
        this.$store.dispatch(LOCATION_LIST_REQUEST, {
          system: systemId,
        });
      }
    });
    this.$store.dispatch(SYSTEM_LIST_REQUEST);
    this.$store.dispatch(LOCATION_LIST_REQUEST);
  },
  mounted() {},
  methods: {
    updateSVLStock(svl) {
      this.$store.dispatch(SPECIES_VARIANT_LOCATION_STOCK, svl);
    },
    deleteLocation(location) {
      // if (confirm(`Are you sure you want to delete ${location.name}?`)) {
      this.$store.dispatch(LOCATION_DELETE_REQUEST, location).then(() => {
        this.$store.dispatch(LOCATION_LIST_REQUEST);
      });
      // }
    },
    showAddModal(locationId) {
      console.log(locationId);
      this.locationToAddToID = locationId;
      this.$bvModal.show("add-species");
    },
    showMoveModal(location, variant) {
      this.locationToRemoveFrom = location;
      this.variantToAddID = variant.id;
      this.variantToAdd = variant;
      this.$bvModal.show("move-species");
    },
    addVariantToLocation() {
      this.$store
        .dispatch(SPECIES_VARIANT_LOCATION_ADD, {
          variant: this.variantToAddID,
          location: this.locationToAddTo,
        })
        .then(() => {
          this.$store.dispatch(LOCATION_LIST_REQUEST, {
            system: this.locationToAddTo.system.id,
          });
          this.$bvModal.hide("add-species");
          this.$bvModal.hide("move-species");
        });
    },
    removeVariantFromLocation(variant, location, shouldConfirm) {
      if (
        shouldConfirm &&
        !confirm("Are you sure you want to remove this variant?")
      ) {
        return;
      }
      this.$store
        .dispatch(SPECIES_VARIANT_LOCATION_DELETE, {
          variant,
          location,
        })
        .then(() => {
          if (shouldConfirm) {
            this.$bvModal.hide("add-species");
          }
          this.$store.dispatch(LOCATION_LIST_REQUEST, {
            system: location.system.id,
          });
        });
    },
    moveVariantToLocation() {
      this.removeVariantFromLocation(
        this.variantToAddID,
        this.locationToRemoveFrom,
        false
      );
      this.addVariantToLocation();
      this.resetModals();
    },
    recordDeath(location, variant) {
      eventBus.$emit("record-death", location, variant);
    },
    resetModals() {
      this.variantToAddID = null;
      this.locationToRemoveFrom = null;
    },
  },
};
</script>
<style lang="scss">
.btn {
  &.collapsed {
    .text-expanded,
    .bi-chevron-up {
      display: none;
    }
  }
  &.not-collapsed {
    .text-collapsed,
    .bi-chevron-down {
      display: none;
    }
  }
}
</style>

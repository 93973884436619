<template lang="pug">
#user-edit(v-if="user")
  .mb-5
    h1 Edit {{ user.name }}
    b-form(@submit.prevent="saveUser")
      b-form-group#name-group(label="Name: ", label-for="name")
        b-form-input#name(
          v-model="user.name",
          type="text",
          required,
          placeholder="Name"
        )
      b-form-group#email-group(label="Username: ", label-for="username")
        b-form-input#email(
          v-model="user.username",
          type="text",
          placeholder="Username"
        )
      b-form-group#passcode-group(label="Passcode: ", label-for="passcode")
        b-form-input#phone(
          v-model="user.passcode",
          type="text",
          placeholder="passcode",
          maxlength="6",
        )
      b-form-group#user-permission-group(
        label="User Permission Level: ",
        label-for="user-permission"
      )
        EnumSelect(
          v-model="user.permission_level",
          placeholder="Select User Permission Level",
          :enum="UserPermissionsLevel"
        )

      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import { USER_LIST_REQUEST, USER_EDIT_REQUEST } from "@/store/actions/user";
import EnumSelect from "@/components/select/EnumSelect.vue";
import { UserPermissionsLevel } from "@/constants";

export default {
  name: "UserEdit",
  components: {
    EnumSelect,
  },
  data() {
    return {
      user: null,
      UserPermissionsLevel,
    };
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
  created() {
    this.$store.dispatch(USER_LIST_REQUEST).then(() => {
      let toEdit = this.getUsers.find(
        (user) => user.id === parseInt(this.$route.params.userId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          name: "",
          username: "",
          passcode: "",
        };
      }
      this.user = toEdit;
    });
  },
  methods: {
    saveUser() {
      this.$store.dispatch(USER_EDIT_REQUEST, this.user).then(() => {
        this.$router.push("/user/list");
      });
    },
  },
};
</script>

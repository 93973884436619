<template lang="pug">
#product-list
  .mb-1
    h1 Products
    b-button(to="./new", variant="success") Add New
  b-table(small stacked="lg" :items="getProducts", :fields="fields", responsive, striped, dark)
    template(#cell(species)="data")
      span {{ data.item.species.common_name }} &nbsp;
      em ({{ data.item.species.scientific_name }})
    template(#cell(locations)="data")
      div(v-for="location in data.item.locations", :key="location.id") {{ location.location.name }}
    template(#cell(actions)="data")
      b-button(:to="`./${data.item.id}`", variant="primary") Edit
</template>
<script>
import { mapGetters } from "vuex";
import { PRODUCT_LIST_REQUEST } from "@/store/actions/product";

export default {
  name: "ProductsList",
  data() {
    return {
      fields: [
        {
          key: "clover_id",
          label: "Clover ID",
        },
        "species",
        "locations",
        "actions",
      ],
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
  },
  created() {
    this.$store.dispatch(PRODUCT_LIST_REQUEST);
  },
};
</script>

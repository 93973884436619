<template lang="pug">
#systems-list
  .mb-5
    h1 Edit {{ system.name }}
    b-form(@submit.prevent="saveSystem")
      template(v-if="system")
        b-form-group#name-group(label="Name: ", label-for="name")
          b-form-input#name(
            v-model="system.name",
            type="text",
            required,
            placeholder="System Name"
          )
        b-form-group#environment-type-group(
          label="Environment Type: ",
          label-for="environment-type"
        )
          EnumSelect(
            v-model="system.environment_type",
            :enum="EnvironmentType",
            placeholder="Select Environment Type"
          )
        b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  SYSTEM_LIST_REQUEST,
  SYSTEM_EDIT_REQUEST,
} from "@/store/actions/system";
import EnumSelect from "@/components/select/EnumSelect.vue";
import { EnvironmentType } from "@/constants";

export default {
  name: "SystemEdit",
  components: {
    EnumSelect,
  },
  data() {
    return {
      system: null,
      EnvironmentType,
    };
  },
  computed: {
    ...mapGetters(["getSystems"]),
  },
  created() {
    this.$store.dispatch(SYSTEM_LIST_REQUEST).then(() => {
      let toEdit = this.getSystems.find(
        (system) => system.id === parseInt(this.$route.params.systemId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          name: "",
          environment_type: null,
        };
      }
      this.system = toEdit;
    });
  },
  methods: {
    saveSystem() {
      this.$store.dispatch(SYSTEM_EDIT_REQUEST, this.system).then(() => {
        this.$router.push("/systems/list");
      });
    },
  },
};
</script>

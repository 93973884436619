<template lang="pug">
div.d-flex.w-100
  v-select.w-75(
    placeholder="Select Customer",
    :value="value",
    :options="getCustomers",
    @input="$emit('input', $event)",
    :reduce="(customer) => customer.id",
    label="name",
    :multiple="multiple",
    :filter="fuseSearch",
  )
    template(v-slot:search="{ attributes, events }")
      input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
    template(v-slot:option="option")
      span {{ option | formatCustomer }}
    template(v-slot:selected-option="option")
      span {{ option | formatCustomer }}
  b-button.d-inline(v-b-modal.customer-modal, variant="primary") Add
  b-modal#customer-modal(
    title="Add Customer",
    :hide-footer="true"
    header-bg-variant="dark"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
  )
    CustomerEdit(:modal="true")
</template>

<script>
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import { CUSTOMER_LIST_REQUEST } from "@/store/actions/customer";
import CustomerEdit from "@/views/customer/CustomerEdit.vue";
import { formatCustomer } from "@/utils";
export default {
  name: "CustomerSelect",
  components: {
    CustomerEdit,
  },
  filters: {
    formatCustomer,
  },
  props: {
    value: [Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["getCustomers", "customerStatus"]),
  },
  created() {
    if (this.customerStatus == "init") {
      this.$store.dispatch(CUSTOMER_LIST_REQUEST);
    }
  },
  methods: {
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name", "email_address", "phone_number"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
  },
};
</script>

import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_EDIT_REQUEST,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_LOADING_START,
  CUSTOMER_LOADING_END,
} from "../actions/customer";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getCustomers: (state) => state.customers,
    customerStatus: (state) => state.status,
  },
  state: {
    customers: [],
    status: "init",
  },
  actions: {
    [CUSTOMER_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(CUSTOMER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/customer/`)
          .then((resp) => {
            const customers = resp.data;
            commit(CUSTOMER_LIST_SUCCESS, customers);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CUSTOMER_LOADING_END));
      }),
    [CUSTOMER_DETAILS_REQUEST]: ({ commit }, customerId) =>
      new Promise((resolve, reject) => {
        commit(CUSTOMER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/customer/${customerId}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CUSTOMER_LOADING_END));
      }),
    [CUSTOMER_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(CUSTOMER_LOADING_START);
        axios
          .post(`${API_BASE_URL}/customer/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Customer",
              body: `Customer ${data.name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CUSTOMER_LOADING_END));
      }),
    [CUSTOMER_DELETE_REQUEST]: ({ commit, dispatch }, customer) =>
      new Promise((resolve, reject) => {
        commit(CUSTOMER_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/customer/${customer.id}`, customer)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Customer",
              body: `Customer ${customer.name} has been successfully deleted.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(CUSTOMER_LOADING_END));
      }),
  },
  mutations: {
    [CUSTOMER_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [CUSTOMER_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [CUSTOMER_LIST_SUCCESS]: (state, customers) => {
      Vue.set(state, "customers", customers);
    },
  },
};

export default module;

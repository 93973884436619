<template lang="pug">
v-select(
  v-if="getLocations.length"
  placeholder="Select Location",
  :value="value",
  :options="getLocations",
  @input="$emit('input', $event)",
  :reduce="(location) => location.id",
  :getOptionLabel="(location) => `${location.name} (${location.system.name})`",
  :multiple="multiple"
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import { LOCATION_LIST_REQUEST } from "@/store/actions/location";
export default {
  name: "LocationSelect",
  props: {
    value: [Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    options() {
      return this.getLocations;
    },
    ...mapGetters(["getLocations", "locationStatus"]),
  },
  created() {
    if (this.locationStatus != "loading") {
      this.$store.dispatch(LOCATION_LIST_REQUEST);
    }
  },
};
</script>

<template lang="pug">
v-select(
  placeholder="Select Species Variant",
  v-model="input"
  @input="updateValue"
  @change="updateValue"
  :appendToBody="true"
  @search="search"
  @search:blur="handleBlur"
  :options="options",
  :reduce="reduceOption",
  :getOptionLabel="friendlyOption",
  :getOptionKey="getOptionKey",
)
  template(v-slot:option="option")
    span {{ friendlyOption(option) }}
  template(v-slot:selected-option="option")
    span {{ friendlyOption(option) }}
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { SPECIES_SEARCH_REQUEST } from "@/store/actions/species";
import _ from "lodash";
export default {
  name: "SpeciesVariantSelect",
  props: {
    value: [Number, Object],
    required: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    environmentType: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      input: null,
      options: [],
      query: {
        variant: true,
        page: 1,
        perPage: 20,
      },
    };
  },
  created() {
    this.fetchOptions = _.debounce((search, loading, vm, isID = false) => {
      if (isID) {
        vm.query.species_variant_id = search;
      } else {
        vm.query.searchQuery = search;
      }
      vm.$store.dispatch(SPECIES_SEARCH_REQUEST, vm.query).then(({ data }) => {
        vm.options = data;
        delete vm.query.species_variant_id;
        loading(false);
      });
    }, 300);

    if (this.$props.value) {
      this.input = this.$props.value;
      this.fetchOptions(this.$props.value, () => {}, this, true);
    }
  },
  methods: {
    friendlyOption(option) {
      let toDisplay = "";
      if (!option.species) {
        return toDisplay;
      }
      if (option.species.common_name) {
        toDisplay += `${option.species.common_name}, `;
      }
      if (option.description) {
        toDisplay += `${option.description} `;
      }
      toDisplay += `(${option.species.scientific_name})`;
      return toDisplay;
    },
    search(search, loading) {
      if (search !== "") {
        this.fetchOptions(search, loading, this);
      }
    },
    fetchOptions() {},
    updateValue(value) {
      this.$emit("input", value);
    },
    handleBlur() {
      // this.fetchOptions(this.value, () => {}, this);
    },
    reduceOption(option) {
      if (this.returnObject) {
        return option;
      } else {
        return option.id;
      }
    },
    getOptionKey(option) {
      return option.id;
    },
  },
};
</script>

<template lang="pug">
v-select(
  placeholder="Select Shipment",
  :value="value",
  :options="shipments",
  @input="$emit('input', $event)",
  :reduce="(shipment) => shipment.shipment_item_id"
)
  template(v-slot:option="option")
    span {{ option.arrival_date | date }} ({{ option.supplier_name }}, {{ option.num_received }})
  template(v-slot:selected-option="option")
    span {{ option.arrival_date | date }} ({{ option.supplier_name }}, {{ option.num_received }})
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import Vue from "vue";
import { SHIPMENT_LIST_BY_VARIANT_REQUEST } from "@/store/actions/shipment";
import { formatDate } from "@/utils";
export default {
  name: "ShipmentByVariantSelect",
  filters: {
    date: (inDate) => formatDate(inDate),
  },
  props: {
    variant: Number,
    value: Number,
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shipments: [],
    };
  },
  watch: {
    variant() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.variant) {
        this.$store
          .dispatch(SHIPMENT_LIST_BY_VARIANT_REQUEST, this.variant)
          .then((resp) => {
            Vue.set(this, "shipments", resp.data);
          });
      }
    },
  },
};
</script>

<template lang="pug">
v-select#supplier-id(
  placeholder="Select Supplier",
  :value="value",
  :options="getSuppliers",
  @input="$emit('input', $event)",
  :reduce="(supplier) => supplier.id",
  label="name",
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import { SUPPLIER_LIST_REQUEST } from "@/store/actions/supplier";
export default {
  name: "SupplierSelect",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["getSuppliers", "supplierStatus"]),
  },
  created() {
    if (this.supplierStatus !== "loading") {
      this.$store.dispatch(SUPPLIER_LIST_REQUEST);
    }
  },
};
</script>

<template lang="pug">
b-modal.record-death(
  id="record-death"
  ok-title="Save"
  title="Record Death"
  no-close-on-backdrop
  @ok="saveDeath"
  @hidden="resetModal"
  static
  header-bg-variant="dark"
  header-text-variant="light"
  body-bg-variant="dark"
  body-text-variant="light"
  footer-bg-variant="dark"
  footer-text-variant="light"
)
  DeathEdit(:modal="true")
</template>

<script>
import DeathEdit from "@/views/death/DeathEdit.vue";
import eventBus from "../eventbus";

export default {
  name: "RecordDeathModal",
  components: {
    DeathEdit,
  },
  methods: {
    saveDeath() {
      eventBus.$emit("save-death-modal");
    },
    resetModal() {
      eventBus.$emit("reset-death-modal");
    },
  },
};
</script>

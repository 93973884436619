<template lang="pug">
div
  div(v-if="multiple")
    b-form-checkbox-group(
      buttons
      :stacked="stacked"
      @input="$emit('input', $event)",
      v-model="innerValue",
      :required="required"
      button-variant="primary"
    )
      b-form-checkbox(
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :variant="innerValue?.includes(option.value) ? 'primary' : 'secondary'"
      ) {{ option.text }}
        b-icon(v-if="innerValue?.includes(option.value)" icon="check")
  div(v-else)
    b-form-radio-group(
      buttons
      :stacked="stacked"
      @input="$emit('input', $event)",
      v-model="innerValue",
      :required="required"
      button-variant="primary"
    )
      b-form-radio(
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :variant="innerValue == option.value ? 'primary' : 'secondary'"
      ) {{ option.text }}
        b-icon(v-if="innerValue == option.value" icon="check")
      b-button(@click="reset" v-if="clearable" variant="danger") Clear
</template>

<script>
export default {
  name: "EnumButtonGroup",
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    enum: Object,
    stacked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: null,
    };
  },
  computed: {
    options() {
      let options = [];
      for (const key in this.enum) {
        options.push({
          value: this.enum[key],
          text: key,
        });
      }
      return options;
    },
  },
  created() {
    if (this.multiple) {
      this.innerValue = this.value || [];
    } else {
      this.innerValue = this.value;
    }
  },
  methods: {
    reset() {
      this.innerValue = this.multiple ? [] : null;
    },
  },
};
</script>

<template lang="pug">
#locations-edit(v-if="location")
  .mb-5
    h1 Edit {{ location.name }}
    b-form(@submit.prevent="saveLocation")
      b-form-group#name-group(label="Name: ", label-for="name")
        b-form-input#name(
          v-model="location.name",
          type="text",
          required,
          placeholder="Location Name"
        )
      b-form-group#system-group(label="System: ")
        SystemSelect(v-model="location.system")
      b-form-group#qt-group(label="Is QT: ")
        b-form-checkbox(v-model="location.is_qt")
      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOCATION_LIST_REQUEST,
  LOCATION_EDIT_REQUEST,
} from "@/store/actions/location";
import SystemSelect from "@/components/select/SystemSelect.vue";

export default {
  name: "LocationEdit",
  components: {
    SystemSelect,
  },
  data() {
    return {
      location: null,
      variants: null,
      fields: ["name"],
    };
  },
  computed: {
    ...mapGetters(["getLocations"]),
  },
  created() {
    if (!this.getLocations.length) {
      this.$store.dispatch(LOCATION_LIST_REQUEST).then(() => {
        this.setLocation();
      });
    } else {
      this.setLocation();
    }
  },
  methods: {
    setLocation() {
      let toEdit = this.getLocations.find(
        (location) => location.id === parseInt(this.$route.params.locationId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          name: "",
          system: null,
          is_qt: true,
        };
      } else {
        toEdit.system = toEdit.system.id;
      }
      this.location = toEdit;
    },

    saveLocation() {
      this.$store.dispatch(LOCATION_EDIT_REQUEST, this.location).then(() => {
        this.$router.push("/location/list");
      });
    },
  },
};
</script>

<template lang="pug">
#species-list
  .mb-1
    h1 Species Sub Types
    b-button(to="./new", variant="success") Add New
  b-table(
    small
    stacked="lg" 
    :items="getSpeciesSubTypes",
    :fields="fields",
    responsive,
    striped,
    dark
  )
    template(#cell(actions)="data")
      b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
</template>
<script>
import { mapGetters } from "vuex";
import { SPECIES_SUBTYPE_LIST_REQUEST } from "@/store/actions/species";
import { SpeciesType } from "@/constants";

export default {
  name: "SpeciesList",
  data() {
    return {
      fields: [
        {
          key: "species_type",
          formatter: (val) =>
            Object.keys(SpeciesType).find((key) => SpeciesType[key] === val),
        },
        "name",
        "actions",
      ],
    };
  },
  computed: {
    ...mapGetters(["getSpeciesSubTypes"]),
  },
  created() {
    this.$store.dispatch(SPECIES_SUBTYPE_LIST_REQUEST);
  },
};
</script>

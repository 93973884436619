import { DateTime } from "luxon";
export const formatDate = (inDate) => {
  return inDate
    ? DateTime.fromISO(inDate).toLocaleString(DateTime.DATE_SHORT)
    : "N/A";
};
export const formatCustomer = (customer) => {
  let toDisplay = "";
  if (!customer) return toDisplay;
  if (customer.name) {
    toDisplay += customer.name;
  }
  if (customer.phone_number) {
    toDisplay += ` (${formatPhoneNumber(customer.phone_number)}) `;
  }
  if (customer.email_address) {
    toDisplay += ` (${customer.email_address})`;
  }
  return toDisplay;
};

export const formatPhoneNumber = (phoneNumber) => {
  // handle if there's - or not to format the phone number to ###-###-####
  if (!phoneNumber) return "";
  let phone = phoneNumber;
  if (phone.length === 10) {
    phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return phone;
};

export const formatVariant = (variant) => {
  let toDisplay = "";
  if (!variant) return toDisplay;
  if (variant.species) {
    toDisplay += `${variant.species.scientific_name} -`;
  }
  if (variant.description) {
    toDisplay += ` ${variant.description}`;
  }
  if (variant.species?.common_name) {
    toDisplay += ` ${variant.species.common_name}`;
  }
  if (variant.collection_location) {
    toDisplay += `, ${variant.collection_location}`;
  }
  return toDisplay;
};

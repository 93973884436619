<template lang="pug">
div.d-flex.w-100
  v-select.w-75(
    :options="options",
    :reduce="reduceOption",
    v-model="input"
    @input="updateValue"
    @change="updateValue"
    :appendToBody="true"
    @search="fetchOptions"
    @search:blur="handleBlur"
  )
    template(v-slot:search="{ attributes, events }")
      input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
    template(v-slot:option="option")
      template(v-if="option")
        span(v-html="option.label")
    template(v-slot:selected-option="option")
      template(v-if="option")
          span(v-html="option.label")
  a(
    :href="`https://www.clover.com/inventory/m/YPVRAH1F15ZXT/items/${value}`"
    target="_blank"
  )
    b-button.d-inline(
      variant="primary"
    )
      b-icon(icon="box-arrow-up-right")
  b-button.d-inline(
    variant="primary"
    @click="updateItems"
    :disabled="cloverStatus == 'loading'"
  )
    b-icon(icon="arrow-repeat" :animation="cloverStatus == 'loading' ? 'spin' : ''")
</template>

<script>
import { mapGetters } from "vuex";
import {
  CLOVER_SEARCH_REQUEST,
  CLOVER_SYNC_REQUEST,
} from "@/store/actions/clover";
export default {
  name: "CloverItemSelect",
  props: {
    value: [String],
    required: {
      type: Boolean,
      default: true,
    },
    livestockFilter: {
      type: String,
      default: "all",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: "",
      options: [],
      query: {
        searchQuery: "",
        page: 1,
        perPage: 20,
      },
    };
  },
  computed: {
    ...mapGetters(["cloverStatus"]),
  },
  watch: {
    value: {
      handler: function (value) {
        this.input = value;
      },
      immediate: true,
    },
  },
  created() {
    if (this.$props.value) {
      this.fetchOptions(this.$props.value, () => {});
    }
  },
  methods: {
    fetchOptions(search, loading) {
      loading(true);
      if (search === "") {
        this.options = [];
        loading(false);
        return;
      }
      this.query.searchQuery = search;
      this.$store
        .dispatch(CLOVER_SEARCH_REQUEST, this.query)
        .then(({ items }) => {
          const options = items.map((item) => {
            if (item.variants?.length > 0) {
              const variant = item.variants[0];
              return {
                variant,
                cloverItem: item,
                label: `<b>${item.name} - $${item.price.toFixed(2)}</b>`,
              };
            } else {
              return {
                cloverItem: item,
                label: `${item.name} - $${item.price.toFixed(2)}`,
              };
            }
          });
          this.options = options;
          loading(false);
        });
    },
    reduceOption(option) {
      try {
        return this.returnObject
          ? option.cloverItem
          : option.cloverItem.clover_id;
      } catch (e) {
        console.error(option);
        console.error(e);
      }
    },
    updateValue(value) {
      this.$emit("input", value);
    },
    updateItems() {
      this.$store.dispatch(CLOVER_SYNC_REQUEST);
    },
    handleBlur() {
      this.fetchOptions(this.value, () => {});
      console.log("blur");
    },
    getOptionLabel(option) {
      if (option) {
        if (option.variant) {
          return `${option.variant.variant.description} - ${
            option.variant.species.common_name
          } (${
            option.variant.species.scientific_name
          }) - $${option.cloverItem.price.toFixed(2)}`;
        } else {
          return `${
            option.cloverItem.name
          } - $${option.cloverItem.price.toFixed(2)}`;
        }
      }
      {
        return "";
      }
    },
  },
};
</script>

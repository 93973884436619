import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_REQUEST,
} from "../actions/product";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getProducts: (state) => state.products,
    productStatus: (state) => state.status,
  },
  state: {
    products: [],
    productStatus: "init",
  },
  actions: {
    [PRODUCT_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(PRODUCT_LIST_REQUEST);
        axios
          .get(`${API_BASE_URL}/product/`)
          .then((resp) => {
            const product = resp.data;
            commit(PRODUCT_LIST_SUCCESS, product);
            resolve(resp);
          })
          .catch((err) => reject(err));
      }),
    [PRODUCT_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(PRODUCT_EDIT_REQUEST);
        axios
          .post(`${API_BASE_URL}/product/`, data)
          .then((resp) => {
            commit(PRODUCT_EDIT_SUCCESS);
            dispatch(MESSAGE_ADD, {
              title: "Product",
              body: `Product has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err));
      }),
  },
  mutations: {
    [PRODUCT_LIST_REQUEST]: (state) => {
      state.productStatus = "loading";
    },
    [PRODUCT_EDIT_REQUEST]: (state) => {
      state.productStatus = "loading";
    },
    [PRODUCT_LIST_SUCCESS]: (state, products) => {
      state.productStatus = "loaded";
      Vue.set(state, "products", products);
    },
    [PRODUCT_EDIT_SUCCESS]: (state) => {
      state.productStatus = "loaded";
    },
  },
};

export default module;

<template lang="pug">
#tag-associate
  header
    h1 Associate Tag (Scanner)
  main
    b-form(@submit.prevent="associateTag")
      b-form-group#tag_id-group(label="Tag ID: ", label-for="tag_id")
        b-form-input#tag_id(
          v-model="tag_id",
          type="text",
          required,
          placeholder="A5XXXXXX"
          :class="{ 'is-invalid': !tagValid}"
          ref="tag_id_field"
        )
        template(v-if="tagValid")
          b-form-text
            | Battery: 
            b-icon(:icon="batteryIcon(tag.hardware.battery)" :variant="batteryClass(tag.hardware.battery)") 
            | &nbsp;{{ tag.hardware.battery }} 
            | Connectivity: 
            b-icon(:icon="connectivityIcon(tag.connectivity.status)" :variant="connectivityClass(tag.connectivity.status)") 
            | &nbsp;{{ tag.connectivity.status }} 
      b-form-group#clover-item-1-group(label="Item 1: ")
        b-form-input(
          v-model="clover_id_1"
          type="text"
          required
          placeholder="Scan item QR"
          ref="clover_id_field"
        )
      b-form-group#location-group(label="Location")
        b-form-input(
            v-model="location"
            type="text"
            required
            placeholder="Scan location QR"
            ref="location_field"
          )
      b-form-group(label="Preview" v-if="tag_id && clover_id_1")#preview
        img(v-show="tagStatus == 'loaded'" ref="preview" alt="Preview" class="img-fluid")
        b-spinner(v-show="tagStatus == 'loading'" label="Loading..." variant="primary" size="lg")
      b-button(type="submit", variant="success") Associate
</template>

<script>
import {
  TAG_ASSOCIATE_REQUEST,
  TAG_PREVIEW_REQUEST,
  TAG_DETAILS_REQUEST,
} from "@/store/actions/tag";
import { mapGetters } from "vuex";
// import tagList from "@/test/tags.json";

export default {
  name: "AssociateTag",
  data() {
    return {
      tag: {},
      tag_id: "",
      clover_id_1: "",
      tags: [],
      location: "",
    };
  },
  computed: {
    tagValid() {
      if (this.tag?.labelId) {
        return (
          this.tag.hardware.battery === "GOOD" &&
          this.tag.connectivity.status === "ONLINE"
        );
      }
      return false;
    },
    ...mapGetters(["tagStatus"]),
  },
  watch: {
    tag_id() {
      if (this.tag_id.length != 8) {
        return;
      }
      this.$store.dispatch(TAG_DETAILS_REQUEST, this.tag_id).then((tag) => {
        this.tag = tag;
      });
      this.getPreview();
    },
    clover_id_1() {
      this.getPreview();
    },
  },
  methods: {
    getPreview() {
      if (this.tag_id.length != 8 || this.clover_id_1.length != 13) {
        return;
      }
      this.$store.dispatch(TAG_PREVIEW_REQUEST, this).then((image) => {
        if (this.$refs.preview == null) {
          return;
        }
        this.$refs.preview.src = `data:image/png;base64,${image}`;
      });
    },
    associateTag() {
      const ids = [this.clover_id_1];
      this.$store
        .dispatch(TAG_ASSOCIATE_REQUEST, {
          tag_id: this.tag_id,
          clover_ids: ids,
          location: this.location,
        })
        .then(() => {
          this.tag_id = "";
          this.clover_id_1 = "";
          this.location = "";
          this.$refs.tag_id_field.focus();
        });
    },
    batteryIcon(status) {
      switch (status) {
        case "GOOD":
          return "battery-full";
        case "BAD":
          return "battery-half";
        default:
          return "battery";
      }
    },
    batteryClass(status) {
      switch (status) {
        case "GOOD":
          return "success";
        case "BAD":
        default:
          return "danger";
      }
    },
    connectivityIcon(status) {
      switch (status) {
        case "ONLINE":
          return "wifi";
        case "OFFLINE":
          return "wifi-off";
      }
    },
    connectivityClass(status) {
      switch (status) {
        case "ONLINE":
          return "success";
        case "OFFLINE":
          return "danger";
        default:
          return "battery-empty";
      }
    },
  },
};
</script>

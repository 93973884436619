<template lang="pug">
#order-list
  .mb-1
    h1 Orders
      b-button.ml-2(to="./0", variant="success") Add New
  b-form.mb-3
    h3 Filters
      b-btn.ml-2(@click="resetFilters", variant="secondary") Reset
    b-row
      b-col(cols="12" sm="3") 
        label Search 
        b-form-input(
          v-model="filters.searchQuery",
          placeholder="Search",
          :clearable="true",
        )
      b-col(cols="6" sm="4" md="3")
        label Order Status
        EnumSelect.d-inline(
          v-model="filters.status",
          :enum="OrderItemStatus",
          placeholder="Select Status",
          :multiple="true"
        )
      b-col(cols="6" sm="4" md="3")
        label Assigned Employee 
        UserSelect(
          v-model="filters.employee",
          placeholder="Select Employee",
        )
      b-col(cols="6" sm="4" md="3")
        label Species Type 
        EnumSelect(
          v-model="filters.speciesType",
          :enum="SpeciesType",
          placeholder="Select Species Type",
          :multiple="true"
        )
      b-col(cols="6" sm="4" md="3")
        label Customer
        CustomerSelect(
          v-model="filters.customer",
          placeholder="Select Customer",
        )
      b-col(cols="6" sm="5" md="6")
        label Order Type
        EnumButtonGroup(
          v-model="filters.orderItemType",
          :enum="FreeformType",
        )
      b-col(cols="6" sm="3" md="2")
        label Misc
        b-form-group
          
          b-form-checkbox(v-model="filters.service" button) Service Customer
            b-icon(v-if="filters.service" icon="check")
  .row
    b-table(small :items="searchResults", :fields="fields" striped, dark :perPage="10" :current-page="currentPage" sort-by="order.order_date" sort-desc)
      template(#cell(status)="data")
        EnumSelect(
          v-model="data.item.status",
          :enum="OrderItemStatus",
          placeholder="Select Status",
          @input="updateOrderItemStatus(data.item)"
          :clearable="false"
        )
      template(#cell(freeform)="data")
        div
          template(v-if="data.item.clover_item")
            a(:href="'https://www.clover.com/inventory/m/YPVRAH1F15ZXT/items/' + data.item.clover_item.clover_id" target="_blank") {{ data.item.clover_item.name }}
          template(v-else-if="data.item.variant")
            | {{ data.item.variant | formatVariant }} 
          template(v-else)
            | {{ data.item.freeform }} 
        div
          template(v-if="data.item.clover_item")
            b-badge.mr-1(pill variant="info") Dry Good 
          template(v-else-if="data.item.variant")
            b-badge(pill) {{ data.item.variant.species.environment_type | formatEnvironmentType}}
            b-badge.mr-1(pill variant="info") {{ data.item.variant.species.species_type | formatSpeciesType}}
          template(v-else)
            b-badge(pill) {{ data.item.freeform_type | formatEnvironmentType}}
            b-badge.mr-1(pill variant="warning") Freeform 
      template(#cell(actions)="data")
        b-button(:to="`./${data.item.order.id}`", variant="primary") Edit
        //- b-button(@click="deleteorder(data.item)" variant="danger") Delete
  b-pagination(:total-rows="searchResults.length", :per-page="10", v-model="currentPage")
</template>
<script>
import fuzzysort from "fuzzysort";
import { mapGetters } from "vuex";
import {
  ORDER_ITEM_LIST_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_ITEM_STATUS_EDIT_REQUEST,
} from "@/store/actions/order";
import EnumSelect from "@/components/select/EnumSelect.vue";
import EnumButtonGroup from "@/components/select/EnumButtonGroup.vue";
import UserSelect from "@/components/select/UserSelect.vue";
import CustomerSelect from "@/components/select/CustomerSelect.vue";
import { formatDate, formatCustomer, formatVariant } from "@/utils";
import {
  OrderItemStatus,
  EnvironmentType,
  FreeformType,
  SpeciesType,
} from "@/constants";

const defaultFilters = () => ({
  searchQuery: "",
  status: [OrderItemStatus.Open],
  orderItemType: null,
  employee: null,
  service: false,
  dryGoods: false,
  customer: null,
  speciesType: [],
});

export default {
  name: "OrderList",
  components: {
    EnumSelect,
    UserSelect,
    CustomerSelect,
    EnumButtonGroup,
  },
  filters: {
    formatVariant,
    formatEnvironmentType(val) {
      return Object.keys(EnvironmentType).find(
        (key) => EnvironmentType[key] === val
      );
    },
    formatSpeciesType(val) {
      return Object.keys(SpeciesType).find((key) => SpeciesType[key] === val);
    },
  },
  data() {
    return {
      OrderItemStatus,
      EnvironmentType,
      FreeformType,
      SpeciesType,
      filters: defaultFilters(),
      currentPage: 1,
      fields: [
        {
          key: "order.id",
          label: "ID",
        },
        {
          key: "order.order_date",
          label: "Order Date",
          sortable: true,
          sortDirection: "desc",
          formatter: formatDate,
        },
        {
          key: "quantity",
          label: "QTY",
        },
        {
          key: "freeform",
          label: "Item",
        },
        "status",
        {
          key: "order.customer",
          label: "Customer",
          formatter: formatCustomer,
        },
        "actions",
      ],
    };
  },
  computed: {
    searchResults() {
      const toFilter = this.getOrderItems
        .filter((orderItem) => {
          if (this.filters.status.length) {
            return this.filters.status.indexOf(orderItem.status) > -1;
          } else {
            return true;
          }
        })
        .filter((orderItem) => {
          if (this.filters.orderItemType) {
            return (
              this.filters.orderItemType == orderItem.freeform_type ||
              this.filters.orderItemType ==
                orderItem.variant?.species.environment_type
            );
          } else {
            return true;
          }
        })
        .filter((orderItem) => {
          if (this.filters.employee) {
            return (
              orderItem.order.customer.employee?.id === this.filters.employee
            );
          } else {
            return true;
          }
        })
        .filter((orderItem) => {
          return this.filters.service
            ? orderItem.order.customer.is_service
            : true;
        })
        .filter((orderItem) => {
          if (this.filters.customer) {
            return orderItem.order.customer.id === this.filters.customer;
          } else {
            return true;
          }
        })
        .filter((orderItem) => {
          console.log(orderItem.freeform_type, this.filters.dryGoods);
          return this.filters.dryGoods ? orderItem.freeform_type == 3 : true;
        })
        .filter((orderItem) => {
          return this.filters.speciesType.length
            ? this.filters.speciesType.indexOf(
                orderItem.variant?.species.species_type
              ) > -1
            : true;
        });
      const res = fuzzysort.go(this.filters.searchQuery, toFilter, {
        keys: [
          "freeform",
          "clover_item.name",
          "order.customer.name",
          "order.customer.email",
          "order.customer.phone_number",
          "variant.description",
          "variant.species.common_name",
          "variant.species.scientific_name",
        ],
        threshold: 0.75,
      });
      return this.filters.searchQuery.length ? res.map((r) => r.obj) : toFilter;
    },
    ...mapGetters(["getOrderItems"]),
  },
  created() {
    this.$store.dispatch(ORDER_ITEM_LIST_REQUEST);
  },
  methods: {
    updateOrderItemStatus(newItem) {
      this.$store.dispatch(ORDER_ITEM_STATUS_EDIT_REQUEST, {
        id: newItem.id,
        status: newItem.status,
      });
    },
    deleteOrder(order) {
      if (confirm(`Are you sure you want to delete this order?`)) {
        this.$store.dispatch(ORDER_DELETE_REQUEST, order).then(() => {
          this.$store.dispatch(ORDER_ITEM_LIST_REQUEST);
        });
      }
    },
    resetFilters() {
      this.filters = defaultFilters();
    },
  },
};
</script>

<style>
label {
  word-wrap: none;
}
</style>

<template lang="pug">
#death-list
  .mb-2
    h1 Deaths
    b-button(to="./0", variant="success") Record Death
  DeathListTable(:deaths="getDeaths")
  RecordDeathModal
</template>
<script>
import RecordDeathModal from "@/components/RecordDeathModal.vue";
import DeathListTable from "@/components/table/DeathListTable.vue";
import { mapGetters } from "vuex";
import { DEATH_LIST_REQUEST } from "@/store/actions/death";

export default {
  name: "DeathList",
  components: {
    RecordDeathModal,
    DeathListTable,
  },
  computed: {
    ...mapGetters(["getDeaths"]),
  },
  created() {
    this.$store.dispatch(DEATH_LIST_REQUEST);
  },
};
</script>

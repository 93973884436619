import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  DEATH_LIST_REQUEST,
  DEATH_DETAILS_REQUEST,
  DEATH_LIST_BY_SHIPMENT_REQUEST,
  DEATH_DELETE_REQUEST,
  DEATH_LIST_SUCCESS,
  DEATH_EDIT_REQUEST,
  DEATH_LOADING_START,
  DEATH_LOADING_END,
} from "../actions/death";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getDeaths: (state) => state.deaths,
    deathStatus: (state) => state.status,
  },
  state: {
    deaths: [],
    status: "init",
  },
  actions: {
    [DEATH_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(DEATH_LOADING_START);
        axios
          .get(`${API_BASE_URL}/death/`)
          .then((resp) => {
            const deaths = resp.data;
            commit(DEATH_LOADING_END);
            commit(DEATH_LIST_SUCCESS, deaths);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(DEATH_LOADING_END));
      }),
    [DEATH_LIST_BY_SHIPMENT_REQUEST]: ({ commit }, shipmentId) =>
      new Promise((resolve, reject) => {
        commit(DEATH_LOADING_START);
        axios
          .get(`${API_BASE_URL}/death/by_shipment/${shipmentId}`)
          .then((resp) => {
            const deaths = resp.data;
            commit(DEATH_LOADING_END);
            resolve(deaths);
          })
          .catch((err) => reject(err))
          .finally(() => commit(DEATH_LOADING_END));
      }),
    [DEATH_DETAILS_REQUEST]: ({ commit }, deathId) =>
      new Promise((resolve, reject) => {
        commit(DEATH_LOADING_START);
        axios
          .get(`${API_BASE_URL}/death/${deathId}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(DEATH_LOADING_END));
      }),
    [DEATH_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(DEATH_LOADING_START);
        axios
          .post(`${API_BASE_URL}/death/`, data)
          .then((resp) => {
            commit(DEATH_LOADING_END);
            dispatch(MESSAGE_ADD, {
              title: "Death",
              body: `Death has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(DEATH_LOADING_END));
      }),
    [DEATH_DELETE_REQUEST]: ({ commit, dispatch }, death) =>
      new Promise((resolve, reject) => {
        commit(DEATH_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/death/${death.id}`, death)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Death",
              body: `Death has been successfully deleted.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(DEATH_LOADING_END));
      }),
  },
  mutations: {
    [DEATH_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [DEATH_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [DEATH_LIST_SUCCESS]: (state, deaths) => {
      Vue.set(state, "deaths", deaths);
    },
  },
};

export default module;

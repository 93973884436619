import Vue from "vue";
import Vuex from "vuex";

import system from "./modules/system";
import species from "./modules/species";
import product from "./modules/product";
import location from "./modules/location";
import customer from "./modules/customer";
import supplier from "./modules/supplier";
import shipment from "./modules/shipment";
import region from "./modules/region";
import user from "./modules/user";
import tag from "./modules/tag";
import messages from "./modules/messages";
import clover from "./modules/clover";
import death from "./modules/death";
import order from "./modules/order";
import metadata from "./modules/metadata";

import { Ability } from "@casl/ability";
import abilityPlugin from "./plugins/ability";

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    isLoading(state, getters) {
      return (
        getters.systemStatus == "loading" ||
        getters.speciesStatus == "loading" ||
        getters.variantStatus == "loading" ||
        getters.productStatus == "loading" ||
        getters.subtTypeStatus == "loading" ||
        getters.locationStatus == "loading" ||
        getters.customerStatus == "loading" ||
        getters.supplierStatus == "loading" ||
        getters.regionStatus == "loading" ||
        getters.shipmentStatus == "loading" ||
        getters.tagStatus == "loading" ||
        getters.userStatus == "loading" ||
        getters.deathStatus == "loading" ||
        getters.cloverStatus == "loading" ||
        getters.orderStatus == "loading" ||
        getters.metadataStatus == "loading"
      );
    },
    ability: () => new Ability(),
  },
  state: {},
  mutations: {},
  actions: {},
  modules: {
    system,
    species,
    product,
    location,
    customer,
    supplier,
    shipment,
    region,
    user,
    messages,
    tag,
    clover,
    death,
    order,
    metadata,
  },
  plugins: [abilityPlugin],
});

export const CLOVER_LIST_REQUEST = "CLOVER_LIST_REQUEST";
export const CLOVER_SEARCH_REQUEST = "CLOVER_SEARCH_REQUEST";
export const CLOVER_SEARCH_SUCCESS = "CLOVER_SEARCH_SUCCESS";
export const CLOVER_SYNC_REQUEST = "CLOVER_SYNC_REQUEST";
export const CLOVER_LIST_SUCCESS = "CLOVER_LIST_SUCCESS";
export const CLOVER_LOADING_START = "CLOVER_LOADING_START";
export const CLOVER_LOADING_END = "CLOVER_LOADING_END";
export const CLOVER_SKU_LOOKUP_REQUEST = "CLOVER_SKU_LOOKUP_REQUEST";
export const CLOVER_INVENTORY_UPDATE_REQEUST =
  "CLOVER_INVENTORY_UPDATE_REQEUST";

<template lang="pug">
#user-list
  .mb-1
    h1 Users
    b-button(to="./new", variant="success") Add New
  b-table(small stacked="lg" :items="getUsers", :fields="fields", responsive, striped, dark)
    template(#cell(actions)="data")
      b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
</template>
<script>
import { mapGetters } from "vuex";
import { USER_LIST_REQUEST } from "@/store/actions/user";
import { UserPermissionsLevel } from "@/constants";

export default {
  name: "CustomerList",
  data() {
    return {
      fields: [
        "name",
        "username",
        {
          key: "permission_level",
          formatter: (val) =>
            Object.keys(UserPermissionsLevel).find(
              (key) => UserPermissionsLevel[key] === val
            ),
        },
        "actions",
      ],
    };
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
  created() {
    this.$store.dispatch(USER_LIST_REQUEST);
  },
};
</script>

<template lang="pug">
v-select(
  placeholder="Select System",
  :value="value",
  :options="getSystems",
  @input="$emit('input', $event)",
  :reduce="(system) => system.id",
  label="name"
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import { SYSTEM_LIST_REQUEST } from "@/store/actions/system";
export default {
  name: "SystemSelect",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["getSystems"]),
  },
  created() {
    this.$store.dispatch(SYSTEM_LIST_REQUEST);
  },
};
</script>

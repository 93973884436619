<template lang="pug">
#region-list
  .mb-1
    h1 Regions
    b-button(to="./new", variant="success") Add New
  b-table(small stacked="lg" :items="getRegions", :fields="fields", responsive, striped, dark)
    template(#cell(actions)="data")
      b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
</template>
<script>
import { mapGetters } from "vuex";
import { REGION_LIST_REQUEST } from "@/store/actions/region";

export default {
  name: "CustomerList",
  data() {
    return {
      fields: ["name", "actions"],
    };
  },
  computed: {
    ...mapGetters(["getRegions"]),
  },
  created() {
    this.$store.dispatch(REGION_LIST_REQUEST);
  },
};
</script>

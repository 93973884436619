<template lang="pug">
#customers-list(v-if="customer")
  .mb-5
    h1 Edit {{ customer.name }}
    b-form(@submit.prevent="saveCustomer")
      b-form-group#name-group(label="Name: ", label-for="name")
        b-form-input#name(
          v-model="customer.name",
          type="text",
          required,
          placeholder="Customer Name"
        )
      b-form-group#email-group(label="Email: ", label-for="email")
        b-form-input#email(
          v-model="customer.email_address",
          type="email",
          placeholder="Customer Email"
        )
      b-form-group#phone-group(label="Phone: ", label-for="phone")
        b-form-input#phone(
          v-model="customer.phone_number",
          type="tel",
          placeholder="Customer Phone"
        )
      b-form-group#contact-pref-group(label="Contact Preference")
        EnumSelect(
          v-model="customer.contact_preference",
          :enum="ContactPreference",
          placeholder="Select Contact Preference"
        )
      b-form-group#service-group(label="Is Service: ")
        b-form-checkbox(v-model="customer.is_service")
      b-form-group#address-group(label="Address: ", label-for="Address")
        b-form-input#address(
          v-model="customer.address",
          type="Address",
          placeholder="Customer Address"
        )
      b-form-group#contact-pref-group(label="Assigned Employee")
        UserSelect(
          v-model="customer.employee",
          placeholder="Select Employee"
        )
      b-form-group#notes-group(label="Notes: ", label-for="notes")
          b-form-textarea#notes(
            v-model="customer.notes",
            type="text",
            placeholder="Customer Notes"
          )

      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_EDIT_REQUEST,
} from "@/store/actions/customer";
import EnumSelect from "@/components/select/EnumSelect.vue";
import UserSelect from "@/components/select/UserSelect.vue";
import { ContactPreference } from "@/constants";
import eventBus from "@/eventbus";

export default {
  name: "CustomerEdit",
  components: {
    EnumSelect,
    UserSelect,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customer: null,
      ContactPreference,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  created() {
    this.$store
      .dispatch(CUSTOMER_DETAILS_REQUEST, this.$route.params.customerId || 0)
      .then((customer) => {
        let toEdit = customer;
        if (!toEdit) {
          toEdit = {
            id: null,
            name: "",
            email_address: "",
            phone_number: "",
            contact_preference: ContactPreference.SMS,
            employee: this.getCurrentUser.id,
            is_service: false,
            address: "",
            notes: "",
          };
        }
        this.customer = toEdit;
      });
  },
  methods: {
    saveCustomer() {
      this.$store
        .dispatch(CUSTOMER_EDIT_REQUEST, this.customer)
        .then(({ data }) => {
          if (!this.$props.modal) {
            this.$router.push("/customer/list");
          } else {
            this.$store.dispatch(CUSTOMER_LIST_REQUEST).then(() => {
              this.$parent.$parent.hide();
              eventBus.$emit("customer-saved", data);
            });
          }
        });
    },
  },
};
</script>

import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import * as Sentry from "@sentry/vue";

import { abilitiesPlugin } from "@casl/vue";
import VueUA from "./plugins/ua";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("VSelect", vSelect);
Vue.use(abilitiesPlugin, store.getters.ability, {
  useGlobalProperties: true,
});
Vue.use(VueUA, { store });

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nprogress/nprogress.css";
import "vue-select/dist/vue-select.css";

import "./assets/scss/styles.scss";

Sentry.init({
  Vue: Vue,
  dsn: "https://e16594f095d840f6dbc7004c31864abb@sentry.jonb.cc/2",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["livestock.l.jonb.cc"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template lang="pug">
.messages
  b-toaster(name="b-toaster-bottom-right")
  Message(v-for="message in getMessages" :key="message.id" :message="message")
</template>

<script>
import { mapGetters } from "vuex";
import Message from "./Message.vue";
export default {
  name: "MessagesContainer",
  components: {
    Message,
  },
  computed: {
    ...mapGetters(["getMessages", "hasMessages"]),
  },
};
</script>

import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  METADATA_LIST_REQUEST,
  METADATA_LIST_SUCCESS,
  METADATA_DETAILS_REQUEST,
  METADATA_LOADING_START,
  METADATA_LOADING_END,
} from "../actions/metadata";

const module = {
  getters: {
    getMetadata: (state) => state.metadata,
    metadataStatus: (state) => state.status,
  },
  state: {
    metadata: {},
    status: "init",
  },
  actions: {
    [METADATA_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(METADATA_LOADING_START);
        axios
          .get(`${API_BASE_URL}/metadata/`)
          .then((resp) => {
            const metadata = resp.data;
            commit(METADATA_LIST_SUCCESS, metadata);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(METADATA_LOADING_END));
      }),
    [METADATA_DETAILS_REQUEST]: ({ commit }, metadataKey) =>
      new Promise((resolve, reject) => {
        commit(METADATA_LOADING_START);
        axios
          .get(`${API_BASE_URL}/metadata/${metadataKey}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(METADATA_LOADING_END));
      }),
  },
  mutations: {
    [METADATA_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [METADATA_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [METADATA_LIST_SUCCESS]: (state, metadata) => {
      Vue.set(state, "metadata", metadata);
    },
  },
};

export default module;

<template lang="pug">
#inventory-audit
  .mb-5
    h1 Inventory Audit
    b-form.col-12.col-md-6.col-lg-4(@submit.prevent="updateInventory")
      b-form-group(label="Barcode")
        BarcodeScanner(v-if="!product" @decode="onDecode")
      template(v-if="product")
        b-form-group(label="Name")
          b-form-input(disabled="disabled" type="text" id="name" v-model="product.name")
        b-form-group(label="Alternative Name")
          b-form-input(disabled="disabled" type="text" id="alternative-name" v-model="product.alternativeName")
        b-form-group(label="Quantity")
          b-form-input(type="number" id="quantity" v-model="product.itemStock.quantity" ref="quantity")
        b-button(type="submit", variant="success") Save



</template>

<script>
// import { StreamBarcodeReader } from "vue-barcode-reader";
import BarcodeScanner from "@/components/BarcodeScanner.vue";
import {
  CLOVER_SKU_LOOKUP_REQUEST,
  CLOVER_INVENTORY_UPDATE_REQEUST,
} from "@/store/actions/clover";

export default {
  name: "InventoryAudit",
  components: {
    BarcodeScanner,
  },
  data() {
    return {
      scanned: false,
      product: null,
    };
  },
  computed: {},
  created() {},
  methods: {
    onDecode(barcode) {
      if (this.scanned) return;
      this.$store
        .dispatch(CLOVER_SKU_LOOKUP_REQUEST, barcode)
        .then((cloverItem) => {
          if (cloverItem) {
            this.product = cloverItem;
            this.scanned = true;
            this.$nextTick(() => {
              this.$refs.quantity.focus();
            });
          }
        });
    },
    updateInventory() {
      this.$store
        .dispatch(CLOVER_INVENTORY_UPDATE_REQEUST, this.product)
        .then(() => {
          this.product = null;
          this.scanned = false;
        });
    },
  },
};
</script>

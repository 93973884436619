<template lang="pug">
#death-edit(v-if="death")
  .mb-5
    h1 Edit Death
    b-form(@submit.prevent="saveDeath")
      b-form-group(label="Species Variant")
        SpeciesVariantSelect.border(v-model="death.variant")
      b-form-group(label="Location")
        LocationSelect.border(v-model="death.location" :required="false")
      b-form-group(label="Shipment" v-if="!death.fromShipment")
        ShipmentByVariantSelect.border(v-model="death.shipment_item" :variant="death.variant")
      b-form-group(label="Date")
        b-form-datepicker#death-timestamp(v-model="death.timestamp")
      b-form-group(label="Quantity")
        b-form-input(
              v-model="death.quantity",
              type="number",
              placeholder="# Dead",
              min="0"
            )

      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import SpeciesVariantSelect from "@/components/select/SpeciesVariantSelect.vue";
import LocationSelect from "@/components/select/LocationSelect.vue";
import ShipmentByVariantSelect from "@/components/select/ShipmentByVariantSelect.vue";
import eventBus from "@/eventbus";
import { DateTime } from "luxon";
import {
  DEATH_DETAILS_REQUEST,
  DEATH_EDIT_REQUEST,
} from "@/store/actions/death";

export default {
  name: "DeathEdit",
  components: {
    SpeciesVariantSelect,
    LocationSelect,
    ShipmentByVariantSelect,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      death: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  created() {
    eventBus.$on("save-death-modal", this.saveDeath);
    eventBus.$on("record-death", (locationID, variantID, shipmentItemID) => {
      this.death = {
        id: null,
        variant: null,
        location: null,
        shipment_item: null,
        quantity: 1,
        timestamp: DateTime.now().toISODate(),
        fromShipment: false,
      };
      this.death.variant = variantID;
      if (locationID) {
        this.death.location = locationID;
      }
      if (shipmentItemID) {
        this.death.shipment_item = shipmentItemID;
        this.death.fromShipment = true;
      }
      this.death.timestamp = DateTime.now().toISODate();
      this.$bvModal.show("record-death");
    });
    if (!this.modal) {
      this.$store
        .dispatch(DEATH_DETAILS_REQUEST, this.$route.params.deathId)
        .then((death) => {
          let toEdit = death;
          if (!toEdit) {
            toEdit = {
              id: null,
              variant: null,
              location: null,
              shipment_item: null,
              quantity: 1,
              timestamp: DateTime.now().toISODate(),
              fromShipment: false,
            };
          } else {
            toEdit.fromShipment = !!toEdit.shipment_item;
          }
          this.death = toEdit;
        });
    }
  },
  methods: {
    saveDeath() {
      this.$store.dispatch(DEATH_EDIT_REQUEST, this.death).then(() => {
        eventBus.$emit("death-saved", this.death);
        if (!this.$props.modal) {
          this.$router.push("/death/list");
        } else {
          this.$bvModal.hide("record-death");
          this.death.id = null;
          this.death.variantID = null;
          this.death.locationID = null;
          this.death.shipmentItemID = null;
          this.death.quantity = 1;
          this.death.date = null;
        }
      });
    },
  },
};
</script>

<template lang="pug">
b-navbar.mb-2(toggleable="lg", type="dark", variant="dark")
  b-navbar-brand(to="/") WC Admin
  b-navbar-toggle(target="nav-collapse")
  b-collapse#nav-collapse(is-nav)
    b-navbar-nav
      b-nav-item(exact-active-class="active" to="/") Home
      b-nav-item-dropdown(text="Retail")
        b-dropdown-item(active-class="active" to="/order/list", v-if="$can('read', 'order')") Orders
        b-dropdown-item(active-class="active" to="/shipment/list", v-if="$can('read', 'shipment')") Shipments
        b-dropdown-item(active-class="active" to="/death/0", v-if="$can('read', 'death')") Record Death
        b-dropdown-item(active-class="active" to="/tag/associate", v-if="$can('associate', 'tag')") Tags
        b-dropdown-item(active-class="active" to="/tag/associate/scanner", v-if="$can('associate', 'tag')") Tags (scanner)
        b-dropdown-item(active-class="active" to="/species/print/stickers", v-if="$can('print', 'sticker')") Stickers
        b-dropdown-item(active-class="active" to="/customer/list", v-if="$can('read', 'customers')") Customers
      b-nav-item-dropdown(text="Service")
        b-dropdown-item(active-class="active" to="/order/list", v-if="$can('read', 'order')") Orders
        b-dropdown-item(active-class="active" to="/customer/list", v-if="$can('read', 'customers')") Customers
      b-nav-item-dropdown(text="Inventory")
        b-dropdown-item(active-class="active" to="/location/list") Locations
        b-dropdown-item(active-class="active" to="/inventory/audit", v-if="$can('manage', 'inventory')") Inventory Audit
        b-dropdown-item(active-class="active" href="https://inventory.watercolorsaquariumgallery.com/reports/low", target="_blank" v-if="$can('manage', 'inventory')") Low Inventory Report
      b-nav-item-dropdown(text="Data", v-if="$can('read', 'data')")
        b-dropdown-item(active-class="active" to="/data") Grafana 
        b-dropdown-item(active-class="active" to="/species/list") Species
        b-dropdown-item(active-class="active" to="/death/list") Deaths
        b-dropdown-item(@click="syncItems") Sync Clover Items
      b-nav-item-dropdown(text="Admin", v-if="$can('manage', 'all')")
        b-dropdown-item(active-class="active" to="/systems/list") Systems
        b-dropdown-item(active-class="active" to="/species/subtype/list") Species Sub Types
        b-dropdown-item(active-class="active" to="/supplier/list") Suppliers
        b-dropdown-item(active-class="active" to="/region/list") Regions
        b-dropdown-item(active-class="active" to="/user/list") Users
    //- b-navbar-nav.ml-auto
    //-   b-nav-item-dropdown(text="Settings", right)
    //-     b-dropdown-divider
    //-     b-dropdown-item(disabled) Client: v{{ clientVersion }}
    b-navbar-nav.ml-auto(v-if="!isLoggedIn")
      b-nav-item(to="/auth/login") Login
    b-navbar-nav.ml-auto(v-if="isLoggedIn")
      b-nav-text(v-if="getCurrentUser")
        b-icon(icon="person-fill")
        span &nbsp; {{ getCurrentUser.username }}
      b-nav-item(@click="logout") Logout
</template>

<script>
import { mapGetters } from "vuex";
import { USER_LOGOUT_REQUEST } from "@/store/actions/user";
import { CLOVER_SYNC_REQUEST } from "@/store/actions/clover";

export default {
  name: "NavigationBar",
  data() {
    return {
      clientVersion: "0.0.0",
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isUserAdmin", "getCurrentUser"]),
  },
  methods: {
    logout() {
      this.$store.dispatch(USER_LOGOUT_REQUEST).then(() => {
        this.$router.push("/");
      });
    },
    syncItems() {
      this.$store.dispatch(CLOVER_SYNC_REQUEST);
    },
  },
};
</script>

<template lang="pug">
#systems-list
  .mb-5
    h1 Systems
    b-button(to="./new", variant="success") Add New
  b-row
    b-col(cols="12", md="3", v-for="system in getSystems", :key="system.id")
      b-card.text-dark(:title="system.name")
        b-card-text {{ system.environment_type | environmentType }}
        b-button(:to="`./${system.id}`", variant="primary") Edit
</template>
<script>
import { mapGetters } from "vuex";
import { SYSTEM_LIST_REQUEST } from "@/store/actions/system";
import { EnvironmentType } from "@/constants";

export default {
  name: "SystemList",
  filters: {
    environmentType: (val) =>
      Object.keys(EnvironmentType).find((key) => EnvironmentType[key] === val),
  },
  computed: {
    ...mapGetters(["getSystems"]),
  },
  created() {
    this.$store.dispatch(SYSTEM_LIST_REQUEST);
  },
};
</script>

<template lang="pug">
#customer-list
  .mb-1
    h1 Customers
    b-button(to="./0", variant="success") Add New
  .row.mb-3
   .col-6
      label Service Customer
      b-form-checkbox(v-model="filterService") 
      label Assigned Employee 
      UserSelect(
        v-model="filterEmployee",
        placeholder="Select Employee",
      )
      label Search 
      b-form-input(
        v-model="searchQuery",
        placeholder="Search",
        :clearable="true",
      )
  b-table(small stacked="lg" :items="searchResults", :fields="fields", responsive, striped, dark)
    template(#cell(actions)="data")
      b-button(:to="`./${data.item.id}`", variant="primary") Edit
      b-button(@click="deleteCustomer(data.item)" variant="danger") Delete
</template>
<script>
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_DELETE_REQUEST,
} from "@/store/actions/customer";
import UserSelect from "@/components/select/UserSelect.vue";
import { ContactPreference } from "@/constants";

export default {
  name: "CustomerList",
  components: {
    UserSelect,
  },
  data() {
    return {
      searchQuery: "",
      filterEmployee: null,
      filterService: false,
      fields: [
        "name",
        {
          key: "employee_name",
          label: "Assigned Employee",
        },
        "email_address",
        {
          key: "is_service",
          formatter: (val) => (val ? "Yes" : "No"),
        },
        "phone_number",
        {
          key: "contact_preference",
          formatter: (val) => ContactPreference[val],
        },
        "actions",
      ],
    };
  },
  computed: {
    searchResults() {
      const toFilter = this.getCustomers
        .filter((customer) =>
          this.filterService ? customer.is_service === this.filterService : true
        )
        .filter((customer) =>
          this.filterEmployee ? customer.employee == this.filterEmployee : true
        );

      const fuse = new Fuse(toFilter, {
        keys: ["name", "email_address", "phone_number"],
      });
      return this.searchQuery.length
        ? fuse.search(this.searchQuery).map(({ item }) => item)
        : toFilter;
    },
    ...mapGetters(["getCustomers"]),
  },
  created() {
    this.$store.dispatch(CUSTOMER_LIST_REQUEST);
  },
  methods: {
    deleteCustomer(customer) {
      if (confirm(`Are you sure you want to delete ${customer.name}?`)) {
        this.$store.dispatch(CUSTOMER_DELETE_REQUEST, customer).then(() => {
          this.$store.dispatch(CUSTOMER_LIST_REQUEST);
        });
      }
    },
  },
};
</script>

<template lang="pug">
#species-list
  header
    .mb-1
      h1 Species
      b-button(to="./0", variant="success") Add New
    .mb-1
      b-row
        b-col(xs="12", md="3")
          b-form-input(v-model="filters.searchQuery", placeholder="Search" debounce="500")
          EnumSelect.d-inline(
              v-model="filters.environment_type",
              :enum="EnvironmentType",
              placeholder="Environment Type",
            )
          b-form-checkbox(v-model="dangerMode") Danger Mode
          b-form-checkbox(v-model="filters.in_stock") In Stock
        b-col(xs="12", md="3")
          EnumSelect.d-inline(
              v-model="filters.species_type",
              :enum="SpeciesType",
              placeholder="Species Type",
            )
          SpeciesSubTypeSelect.d-inline(
              v-if="filters.species_type",
              v-model="filters.species_sub_type",
              placeholder="Species Sub Type",
              :speciesType="filters.species_type",
            )
        b-col(xs="12", md="3")
          RegionSelect.d-inline(
              v-model="filters.region",
              placeholder="Region",
            )
        b-col(xs="12" md="3" v-if="filters.species_type == SpeciesType.Plant")
            EnumSelect.d-inline(
                v-model="filters.placement",
                :enum="Placement",
                placeholder="Placement",
              )
            EnumSelect.d-inline(
                v-model="filters.light_level",
                :enum="LightLevel",
                placeholder="Light Level",
              )
      b-row
        b-col
          p
            b-button(@click="resetFilters") Reset Filters
          p
            b-pagination(v-model="filters.page", :total-rows="results.metadata.total", :per-page="filters.perPage")
          p {{ pageFirstRecord }} - {{ pageLastRecord }} of {{ results.metadata.total }}
  main

    b-table#table(ref="table" small stacked="lg" :items="tableProvider", :fields="fields", responsive, striped, dark :per-page="filters.perPage" :current-page="filters.page")
      template(#row-details="data")
        b-badge.mx-3(pill v-for="variant in data.item.variants" :key="variant.id") {{variant.description ? variant.description : "No Description"}}
      template(#cell(attributes)="data")
        template(v-if="data.item.light_level")
          b-icon(icon="lightbulb") 
          | {{data.item.light_level | light_level}}
        template(v-if="data.item.placement")
          b-icon(icon="pin-map") 
          | {{data.item.placement | placement}}
      //- template(#cell(variants)="data")
      //-   b-button(v-if="data.item.variants.length > 1" @click="data.toggleDetails" size="sm") {{ data.detailsShowing ? '&uarr;' : '&darr;'}} {{data.item.variants.length}}
      template(#cell(actions)="data")
        b-button(:to="`./${data.item.id}`", variant="primary" size="sm") Edit
        b-button(
          v-if="dangerMode",
          @click="deleteSpecies(data.item)",
          variant="danger"
          size="sm"
        ) Delete
  footer
    b-pagination(v-model="filters.page", :total-rows="results.metadata.total", :per-page="filters.perPage")
    p {{ pageFirstRecord }} - {{ pageLastRecord }} of {{ results.metadata.total }}
</template>
<script>
// import { mapGetters } from "vuex";
import {
  SPECIES_SEARCH_REQUEST,
  SPECIES_DELETE_REQUEST,
} from "@/store/actions/species";
import {
  EnvironmentType,
  SpeciesType,
  LightLevel,
  Placement,
} from "@/constants";
import EnumSelect from "@/components/select/EnumSelect.vue";
import SpeciesSubTypeSelect from "@/components/select/SpeciesSubTypeSelect.vue";
import RegionSelect from "@/components/select/RegionSelect.vue";
// import Fuse from "fuse.js";

const defaultFilters = () => ({
  searchQuery: null,
  environment_type: null,
  species_type: null,
  species_sub_type: null,
  region: null,
  placement: null,
  light_level: null,
  page: 1,
  perPage: 25,
  in_stock: false,
});

export default {
  name: "SpeciesList",
  components: {
    EnumSelect,
    SpeciesSubTypeSelect,
    RegionSelect,
  },
  filters: {
    environment_type: (val) =>
      Object.keys(EnvironmentType).find((key) => EnvironmentType[key] === val),
    light_level: (val) =>
      Object.keys(LightLevel).find((key) => LightLevel[key] === val),
    placement: (val) =>
      Object.keys(Placement).find((key) => Placement[key] === val),
  },
  props: {
    public: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      results: {
        data: [],
        metadata: {
          total: 0,
          page: 1,
          pages: 1,
          count: 0,
        },
      },
      dangerMode: false,
      tableFields: [
        "scientific_name",
        "common_name",
        {
          key: "species_type",
          formatter: (val) =>
            Object.keys(SpeciesType).find((key) => SpeciesType[key] === val),
        },
        {
          key: "environment_type",
          formatter: (val) =>
            Object.keys(EnvironmentType).find(
              (key) => EnvironmentType[key] === val
            ),
        },
        {
          key: "species_sub_type",
          formatter: (val) => (val ? val.name : ""),
        },
        {
          key: "region",
          formatter: (val) => (val ? val.name : ""),
        },
        "attributes",
        "variants",
        "actions",
      ],
      filters: defaultFilters(),
      EnvironmentType,
      SpeciesType,
      LightLevel,
      Placement,
    };
  },
  computed: {
    fields() {
      return this.public
        ? this.tableFields.filter((field) => field !== "actions")
        : this.tableFields;
    },

    pageFirstRecord() {
      return this.filters.perPage * (this.filters.page - 1) + 1;
    },
    pageLastRecord() {
      return Math.min(
        this.filters.perPage * this.filters.page,
        this.results.metadata.total
      );
    },
    // ...mapGetters(["getSpecies"]),
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        const query = {};
        for (const key in this.filters) {
          if (this.filters[key] !== null) {
            query[key] = this.filters[key];
          }
        }
        //only update if different than existing query
        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.push({ query });
        }
        this.$root.$emit("bv::refresh::table", "table");
      },
    },
  },
  created() {
    // set filters from query params
    for (const key in this.$route.query) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.filters.hasOwnProperty(key)) {
        if (key === "in_stock") {
          this.filters[key] = this.$route.query[key] === "true";
          continue;
        }
        if (key !== "searchQuery" && this.$route.query[key] !== "") {
          this.filters[key] = parseInt(this.$route.query[key]);
        } else {
          this.filters[key] = this.$route.query[key];
        }
      }
    }
    this.search();
  },
  methods: {
    search() {
      return this.$store
        .dispatch(SPECIES_SEARCH_REQUEST, this.filters)
        .then((resp) => {
          this.results = resp;
          return resp;
        });
    },
    tableProvider(ctx, callback) {
      this.search().then(() => {
        callback(this.results.data);
      });
    },
    deleteSpecies(species) {
      if (
        confirm(
          `Are you sure you want to delete species ${species.scientific_name}? This will remove all variants, and any related shipment items. `
        )
      ) {
        this.$store.dispatch(SPECIES_DELETE_REQUEST, species).then(() => {
          this.search();
        });
      }
    },
    resetFilters() {
      this.filters = defaultFilters();
    },
  },
};
</script>

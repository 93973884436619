<template lang="pug">
.metadata-display
  .value(v-if="metadataType == 'string'") {{ value }}
  .value(v-if="metadataType == 'array'")
    ul
      li(v-for="(item, index) in value" :key="index") {{ item }}
  .value(v-if="metadataType == 'object'")
    b-table(:items="tableRows" striped hover small dark)
</template>

<script>
import { METADATA_DETAILS_REQUEST } from "@/store/actions/metadata";
export default {
  name: "MetadataDisplay",
  props: {
    metadataKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: null,
      metadataType: null,
    };
  },
  computed: {
    tableRows() {
      return Object.keys(this.value).map((key) => ({
        key,
        value: this.value[key],
      }));
    },
  },
  created() {
    this.$store
      .dispatch(METADATA_DETAILS_REQUEST, this.metadataKey)
      .then((value) => {
        if (Array.isArray(value)) {
          this.metadataType = "array";
        } else if (typeof value === "object") {
          this.metadataType = "object";
        } else {
          this.metadataType = "string";
        }
        this.value = value;
      });
  },
};
</script>

import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  SPECIES_LOADING_START,
  SPECIES_LOADING_END,
  SPECIES_LIST_REQUEST,
  SPECIES_LIST_SUCCESS,
  SPECIES_DETAILS_REQUEST,
  SPECIES_SEARCH_REQUEST,
  SPECIES_VARIANT_LIST_REQUEST,
  SPECIES_DELETE_REQUEST,
  SPECIES_VARIANT_DELETE_REQUEST,
  SPECIES_VARIANT_LIST_BY_LOCATION_REQUEST,
  SPECIES_SUBTYPE_LIST_REQUEST,
  SPECIES_SUBTYPE_LIST_SUCCESS,
  SPECIES_EDIT_REQUEST,
  SPECIES_SUBTYPE_EDIT_REQUEST,
  SPECIES_VARIANT_LOCATION_ADD,
  SPECIES_VARIANT_LOCATION_DELETE,
  SPECIES_VARIANT_LOCATION_STOCK,
  SPECIES_LIVESTOCK_LIST_REQUEST,
  SPECIES_SEARCH_SUCCESS,
} from "../actions/species";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getSpecies: (state) => state.species,
    getVariants: (state) => state.variants,
    getVariantById: (state) => (id) => state.variants.find((v) => v.id === id),
    getSpeciesSubTypes: (state) => state.subTypes,
    speciesStatus: (state) => state.speciesStatus,
  },
  state: {
    species: [],
    subTypes: [],
    variants: [],
    speciesStatus: "init",
  },
  actions: {
    [SPECIES_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .get(`${API_BASE_URL}/species/`)
          .then((resp) => {
            const species = resp.data;
            commit(SPECIES_LIST_SUCCESS, species);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_DETAILS_REQUEST]: ({ commit }, speciesId) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .get(`${API_BASE_URL}/species/${speciesId}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_SEARCH_REQUEST]: ({ commit }, filters) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        let url;
        if (filters.variant) {
          url = `${API_BASE_URL}/species/search/variants`;
        } else {
          url = `${API_BASE_URL}/species/search`;
        }
        axios
          .get(url, { params: filters })
          .then(({ data }) => {
            resolve(data);
            if (filters.variant) {
              commit(SPECIES_SEARCH_SUCCESS, data.data);
            }
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_VARIANT_LIST_REQUEST]: ({ commit }, speciesId) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .get(`${API_BASE_URL}/species/${speciesId}/variants`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_VARIANT_LIST_BY_LOCATION_REQUEST]: ({ commit }, locationId) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .get(`${API_BASE_URL}/species/variants/by_location/${locationId}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_SUBTYPE_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .get(`${API_BASE_URL}/species/subtype`)
          .then((resp) => {
            const subTypes = resp.data;
            commit(SPECIES_SUBTYPE_LIST_SUCCESS, subTypes);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .post(`${API_BASE_URL}/species/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species",
              body: `Species ${data.scientific_name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_SUBTYPE_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .post(`${API_BASE_URL}/species/subtype`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species",
              body: `Species Subtype ${data.name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_DELETE_REQUEST]: ({ commit, dispatch }, species) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/species/${species.id}`)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species",
              body: `Species ${species.scientific_name} has been successfully deleted.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_VARIANT_DELETE_REQUEST]: ({ commit, dispatch }, payload) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        const { variant, species } = payload;
        axios
          .delete(
            `${API_BASE_URL}/species/${species.id}/variants/${variant.id}`
          )
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species Variant",
              body: `Species variant ${variant.description} (${variant.clover_item}( has been successfully deleted.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_VARIANT_LOCATION_ADD]: ({ commit, dispatch }, payload) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        const { variant, location } = payload;
        axios
          .post(
            `${API_BASE_URL}/species/variants/${variant}/location/${location.id}`
          )
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species Variant Added",
              body: `Species Variant has been successfully added to ${location.name}.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_VARIANT_LOCATION_DELETE]: ({ commit, dispatch }, payload) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        const { variant, location } = payload;
        axios
          .delete(
            `${API_BASE_URL}/species/variants/${variant}/location/${location.id}`
          )
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species Variant Removed",
              body: `Species Variant has been successfully removed from ${location.name}.`,
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_VARIANT_LOCATION_STOCK]: ({ commit, dispatch }, payload) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .post(`${API_BASE_URL}/species/svl`, payload)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Species Variant Stock",
              body: `Species Variant stock has been successfully updated to ${payload.in_stock}.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
    [SPECIES_LIVESTOCK_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(SPECIES_LOADING_START);
        axios
          .get(`${API_BASE_URL}/species/in_stock/datatables`)
          .then((resp) => {
            const { data } = resp;
            resolve(data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SPECIES_LOADING_END));
      }),
  },
  mutations: {
    [SPECIES_LOADING_START]: (state) => {
      state.speciesStatus = "loading";
    },
    [SPECIES_LOADING_END]: (state) => {
      state.speciesStatus = "loaded";
    },
    [SPECIES_LIST_SUCCESS]: (state, species) => {
      Vue.set(state, "species", species);
    },
    [SPECIES_SEARCH_SUCCESS]: (state, variants) => {
      variants.forEach((variant) => {
        if (state.variants.find((v) => v.id === variant.id)) {
          return;
        } else {
          state.variants.push(variant);
        }
      });
    },
    [SPECIES_SUBTYPE_LIST_SUCCESS]: (state, subTypes) => {
      Vue.set(state, "subTypes", subTypes);
    },
  },
};

export default module;

import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_EDIT_REQUEST,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOADING_START,
  USER_LOADING_END,
} from "../actions/user";

import { MESSAGE_ADD } from "../actions/messages";

import { UserPermissionsLevel } from "@/constants";

const module = {
  getters: {
    getUsers: (state) => state.users,
    userStatus: (state) => state.status,
    getUserToken: (state) => state.token,
    isLoggedIn: (state) => !!state.token,
    getCurrentUser: (state) => state.currentUser,
    isUserAdmin: (state) =>
      state.currentUser?.permission_level == UserPermissionsLevel.Admin,
  },
  state: {
    users: [],
    status: "init",
    token: null,
    currentUser: null,
  },
  actions: {
    [USER_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(USER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/user/`)
          .then((resp) => {
            const users = resp.data;
            commit(USER_LIST_SUCCESS, users);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(USER_LOADING_END));
      }),
    [USER_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(USER_LOADING_START);
        axios
          .post(`${API_BASE_URL}/user/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "User",
              body: `User ${data.username} successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(USER_LOADING_END));
      }),
    [USER_LOGIN_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve) => {
        commit(USER_LOADING_START);
        axios({
          url: `${API_BASE_URL}/user/login`,
          method: "POST",
          data,
        })
          .then((resp) => {
            const { token } = resp.data;
            commit(USER_LOGIN_SUCCESS, token);
            dispatch(MESSAGE_ADD, {
              title: "Login",
              body: "You have been successfully logged in.",
              category: "success",
            });
            resolve(resp);
          })
          .catch(() => {
            dispatch(MESSAGE_ADD, {
              title: "Login",
              body: "Your passcode was incorrect. Please try again.",
              category: "danger",
            });
            commit(USER_LOGIN_ERROR);
          })
          .finally(() => commit(USER_LOADING_END));
      }),
    [USER_PROFILE_REQUEST]: ({ commit, dispatch }) =>
      new Promise((resolve, reject) => {
        commit(USER_LOADING_START);
        axios({
          url: `${API_BASE_URL}/user/me`,
        })
          .then((resp) => {
            const profile = resp.data;
            commit(USER_PROFILE_SUCCESS, profile);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            dispatch(USER_LOGOUT_REQUEST);
          })
          .finally(() => commit(USER_LOADING_END));
      }),
    [USER_LOGOUT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(USER_LOADING_START);
        axios({
          url: `${API_BASE_URL}/user/logout`,
          method: "POST",
          data,
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit(USER_LOGOUT_SUCCESS);
            commit(USER_LOADING_END);
            dispatch(MESSAGE_ADD, {
              title: "Login",
              body: "You have been successfully logged out.",
              category: "success",
            });
          });
      }),
  },
  mutations: {
    [USER_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [USER_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [USER_LIST_SUCCESS]: (state, users) => {
      Vue.set(state, "users", users);
    },
    [USER_LOGIN_SUCCESS]: (state, token) => {
      state.token = token;
      localStorage.setItem("user-token", token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    [USER_PROFILE_SUCCESS]: (state, user) => {
      state.currentUser = user;
    },
    [USER_LOGOUT_SUCCESS]: (state) => {
      localStorage.removeItem("user-token");
      delete axios.defaults.headers.common.Authorization;
      state.token = null;
    },
  },
};

export default module;

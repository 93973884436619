import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  SHIPMENT_LIST_REQUEST,
  SHIPMENT_SEARCH_REQUEST,
  SHIPMENT_LIST_SUCCESS,
  SHIPMENT_EDIT_REQUEST,
  SHIPMENT_DETAILS_REQUEST,
  SHIPMENT_DELETE_REQUEST,
  SHIPMENT_ITEMS_LIST_REQUEST,
  SHIPMENT_ITEMS_LIST_SUCCESS,
  SHIPMENT_LIST_BY_VARIANT_REQUEST,
  SHIPMENT_ITEMS_EDIT_REQUEST,
  SHIPMENT_LOADING_START,
  SHIPMENT_LOADING_END,
} from "../actions/shipment";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getShipments: (state) => state.shipments,
    getShipmentById: (state) => (id) => {
      return state.shipments.find((shipment) => shipment.id === id);
    },
    shipmentStatus: (state) => state.status,
  },
  state: {
    shipments: [],
    shipmentItems: [],
    status: "init",
  },
  actions: {
    [SHIPMENT_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .get(`${API_BASE_URL}/shipment/`)
          .then((resp) => {
            const shipments = resp.data;
            commit(SHIPMENT_LIST_SUCCESS, shipments);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_DETAILS_REQUEST]: ({ commit }, shipmentId) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .get(`${API_BASE_URL}/shipment/${shipmentId}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_SEARCH_REQUEST]: ({ commit }, filters) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .get(`${API_BASE_URL}/shipment/search`, { params: filters })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .post(`${API_BASE_URL}/shipment/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Shipment",
              body: "Shipment has been successfully saved.",
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_DELETE_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/shipment/${data.id}`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Shipment",
              body: "Shipment has been successfully deleted.",
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_LIST_BY_VARIANT_REQUEST]: ({ commit }, variantId) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .get(`${API_BASE_URL}/shipment/by_variant/${variantId}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_ITEMS_LIST_REQUEST]: ({ commit }, shipmentId) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_LOADING_START);
        axios
          .get(`${API_BASE_URL}/shipment/${shipmentId}/items`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
    [SHIPMENT_ITEMS_EDIT_REQUEST]: ({ commit, dispatch }, payload) =>
      new Promise((resolve, reject) => {
        commit(SHIPMENT_ITEMS_EDIT_REQUEST);
        axios
          .post(
            `${API_BASE_URL}/shipment/${payload.shipmentId}/items`,
            payload.shipmentItems
          )
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Shipment Items",
              body: "Shipment items have been successfully saved.",
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SHIPMENT_LOADING_END));
      }),
  },
  mutations: {
    [SHIPMENT_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [SHIPMENT_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [SHIPMENT_LIST_SUCCESS]: (state, shipments) => {
      Vue.set(state, "shipments", shipments);
    },
    [SHIPMENT_ITEMS_LIST_SUCCESS]: (state, shipmentItems) => {
      Vue.set(state, "shipmentItems", shipmentItems);
    },
  },
};

export default module;

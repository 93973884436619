<template lang="pug">
v-select(
  placeholder="Select Species",
  :value="value",
  :options="getSpecies",
  @input="$emit('input', parseInt($event))",
  :reduce="(species) => species.id",
  :filter="fuseSearch",
  :getOptionLabel="(species) => species.common_name + (species.scientific_name ? ' (' + species.scientific_name + ')' : '')",
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import Fuse from "fuse.js";
import { SPECIES_LIST_REQUEST } from "@/store/actions/species";
export default {
  name: "SpeciesSelect",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["getSpecies"]),
  },
  created() {
    this.$store.dispatch(SPECIES_LIST_REQUEST);
  },
  methods: {
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["common_name", "scientific_name"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
  },
};
</script>

import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  REGION_LIST_REQUEST,
  REGION_LIST_SUCCESS,
  REGION_EDIT_REQUEST,
  REGION_LOADING_START,
  REGION_LOADING_END,
} from "../actions/region";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getRegions: (state) => state.regions,
    regionStatus: (state) => state.status,
  },
  state: {
    regions: [],
    status: "init",
  },
  actions: {
    [REGION_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(REGION_LOADING_START);
        axios
          .get(`${API_BASE_URL}/region/`)
          .then((resp) => {
            const regions = resp.data;
            commit(REGION_LIST_SUCCESS, regions);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(REGION_LOADING_END));
      }),
    [REGION_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(REGION_LOADING_START);
        axios
          .post(`${API_BASE_URL}/region/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Region",
              body: `Region ${data.name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(REGION_LOADING_END));
      }),
  },
  mutations: {
    [REGION_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [REGION_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [REGION_LIST_SUCCESS]: (state, regions) => {
      Vue.set(state, "regions", regions);
    },
  },
};

export default module;

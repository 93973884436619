import ua from "ua-parser-js";

const module = {
  getters: {
    getUserAgent: (state) => state.ua,
    getBrowser: (state) => state.browser,
    getOS: (state) => state.os,
    getDevice: (state) => state.device,
  },
  state: {
    ua: null,
    browser: null,
    os: null,
    device: null,
  },
  mutations: {
    setUAData(state, data) {
      state = Object.assign(state, data);
    },
  },
};

const VueUA = {
  install(Vue, { store }) {
    const uaParser = new ua.UAParser();
    const uaData = uaParser.getResult();
    store.registerModule("ua", module);
    store.commit("setUAData", uaData);
  },
};

export default VueUA;

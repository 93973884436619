import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  SYSTEM_LIST_REQUEST,
  SYSTEM_LIST_SUCCESS,
  SYSTEM_EDIT_REQUEST,
  SYSTEM_LOCATION_POPULATE,
  SYSTEM_LOADING_START,
  SYSTEM_LOADING_END,
} from "../actions/system";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getSystems: (state) => state.systems,
    systemStatus: (state) => state.status,
  },
  state: {
    systems: [],
    status: "init",
  },
  actions: {
    [SYSTEM_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(SYSTEM_LOADING_START);
        axios
          .get(`${API_BASE_URL}/system/`)
          .then((resp) => {
            const systems = resp.data;
            commit(SYSTEM_LIST_SUCCESS, systems);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SYSTEM_LOADING_END));
      }),
    [SYSTEM_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(SYSTEM_LOADING_START);
        axios
          .post(`${API_BASE_URL}/system/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "System",
              body: `System ${data.name} has been successfully saved.`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(SYSTEM_LOADING_END));
      }),
  },
  mutations: {
    [SYSTEM_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [SYSTEM_LOADING_END]: (state) => {
      state.status = "loadED";
    },
    [SYSTEM_LOCATION_POPULATE]: (state, { systemId, locations }) => {
      const system = state.systems.find((system) => system.id === systemId);
      Vue.set(system, "locations", locations);
    },
    [SYSTEM_LIST_SUCCESS]: (state, systems) => {
      Vue.set(state, "systems", systems);
    },
  },
};

export default module;

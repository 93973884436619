import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import axios from "axios";
import store from "@/store";
import { MESSAGE_ADD } from "../store/actions/messages";
import { USER_LOGOUT_SUCCESS } from "../store/actions/user";

import SystemList from "@/views/system/SystemList.vue";
import SystemEdit from "@/views/system/SystemEdit.vue";
import SpeciesList from "@/views/species/SpeciesList.vue";
import SpeciesEdit from "@/views/species/SpeciesEdit.vue";
import SpeciesVariantStickerPrint from "@/views/species/SpeciesVariantStickerPrint.vue";
import SpeciesSubTypeList from "@/views/species/SpeciesSubTypeList.vue";
import SpeciesSubTypeEdit from "@/views/species/SpeciesSubTypeEdit.vue";
import ProductList from "@/views/product/ProductList.vue";
import ProductEdit from "@/views/product/ProductEdit.vue";
import LocationList from "@/views/location/LocationList.vue";
import LocationSummary from "@/views/location/LocationSummary.vue";
import LocationEdit from "@/views/location/LocationEdit.vue";
import LocationDeath from "@/views/location/LocationDeath.vue";
import CustomerList from "@/views/customer/CustomerList.vue";
import CustomerEdit from "@/views/customer/CustomerEdit.vue";
import SupplierList from "@/views/supplier/SupplierList.vue";
import SupplierEdit from "@/views/supplier/SupplierEdit.vue";
import ShipmentList from "@/views/shipment/ShipmentList.vue";
import ShipmentEdit from "@/views/shipment/ShipmentEdit.vue";
import ShipmentItemsPrint from "@/views/shipment/ShipmentItemsPrint.vue";
import RegionList from "@/views/region/RegionList.vue";
import RegionEdit from "@/views/region/RegionEdit.vue";
import UserList from "@/views/user/UserList.vue";
import UserEdit from "@/views/user/UserEdit.vue";
import UserLogin from "@/views/user/UserLogin.vue";
import AssociateTag from "@/views/tag/AssociateTag.vue";
import AssociateTagScanner from "@/views/tag/AssociateTagScanner.vue";
import PrintSticker from "@/views/tag/PrintSticker.vue";
import DeathList from "@/views/death/DeathList.vue";
import DeathEdit from "@/views/death/DeathEdit.vue";
import OrderEdit from "@/views/order/OrderEdit.vue";
import OrderList from "@/views/order/OrderList.vue";
import InventoryAudit from "@/views/inventory/InventoryAudit.vue";
import GrafanaView from "@/views/data/GrafanaView.vue";

Vue.use(VueRouter);

// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isLoggedIn) {
//     next();
//     return;
//   }
//   next("/user/login");
// };

// const ifNotAuthenticated = (to, from, next) => {};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/systems/list",
    name: "SystemList",
    component: SystemList,
    meta: {
      title: "Systems",
    },
  },
  {
    path: "/systems/:systemId",
    name: "SystemEdit",
    component: SystemEdit,
    meta: {
      title: "Systems",
      admin: true,
    },
  },
  {
    path: "/species/list",
    name: "SpeciesList",
    component: SpeciesList,
    meta: {
      title: "Species",
    },
  },
  {
    path: "/species/print/stickers",
    name: "SpeciesVariantStickerPrint",
    component: SpeciesVariantStickerPrint,
    meta: {
      title: "Species Sticker Print",
    },
  },
  {
    path: "/species/:speciesId",
    name: "SpeciesEdit",
    component: SpeciesEdit,
    meta: {
      title: "Species",
      admin: true,
    },
  },
  {
    path: "/species/subtype/list",
    name: "SpeciesSubTypeList",
    component: SpeciesSubTypeList,
    meta: {
      title: "Species Sub Type",
    },
  },
  {
    path: "/species/subtype/:subTypeId",
    name: "SpeciesSubTypeEdit",
    component: SpeciesSubTypeEdit,
    meta: {
      title: "Species Sub Type",
      admin: true,
    },
  },
  {
    path: "/product/list",
    name: "ProductList",
    component: ProductList,
    meta: {
      title: "Product",
    },
  },
  {
    path: "/product/:productId",
    name: "ProductEdit",
    component: ProductEdit,
    meta: {
      title: "Product",
      admin: true,
    },
  },
  {
    path: "/location/list",
    name: "LocationList",
    component: LocationList,
    meta: {
      title: "Locations",
    },
  },
  {
    path: "/location/:locationId",
    name: "LocationEdit",
    component: LocationEdit,
    meta: {
      title: "Locations",
      admin: true,
    },
  },
  {
    path: "/location/:locationId/summary",
    name: "LocationSummary",
    component: LocationSummary,
    meta: {
      title: "Locations",
    },
  },
  {
    path: "/location/:locationId/death",
    name: "LocationDeath",
    component: LocationDeath,
    meta: {
      title: "Locations",
      admin: true,
    },
  },
  {
    path: "/customer/list",
    name: "CustomerList",
    component: CustomerList,
    meta: {
      title: "Customers",
    },
  },
  {
    path: "/customer/:customerId",
    name: "CustomerEdit",
    component: CustomerEdit,
    meta: {
      title: "Customers",
      admin: true,
    },
  },
  {
    path: "/supplier/list",
    name: "SupplierList",
    component: SupplierList,
    meta: {
      title: "Suppliers",
    },
  },
  {
    path: "/supplier/:supplierId",
    name: "SupplierEdit",
    component: SupplierEdit,
    meta: {
      title: "Suppliers",
      admin: true,
    },
  },
  {
    path: "/shipment/list",
    name: "ShipmentList",
    component: ShipmentList,
    meta: {
      title: "Shipments",
    },
  },
  {
    path: "/shipment/:shipmentId",
    name: "ShipmentEdit",
    component: ShipmentEdit,
    meta: {
      title: "Shipments",
      admin: true,
    },
  },
  {
    path: "/shipment/:shipmentId/stickers",
    name: "ShipmentItemsPrint",
    component: ShipmentItemsPrint,
    meta: {
      title: "Shipments",
      admin: true,
    },
  },
  {
    path: "/region/list",
    name: "RegionList",
    component: RegionList,
    meta: {
      title: "Regions",
    },
  },
  {
    path: "/region/:regionId",
    name: "RegionEdit",
    component: RegionEdit,
    meta: {
      title: "Regions",
      admin: true,
    },
  },
  {
    path: "/user/list",
    name: "UserList",
    component: UserList,
    title: "Users",
  },
  {
    path: "/user/:userId",
    name: "UserEdit",
    component: UserEdit,
    meta: {
      title: "Users",
      admin: true,
    },
  },
  {
    path: "/auth/login",
    name: "UserLogin",
    component: UserLogin,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/tag/associate",
    name: "AssociateTag",
    component: AssociateTag,
    meta: {
      title: "Associate Tag",
    },
  },
  {
    path: "/tag/associate/scanner",
    name: "AssociateTagScanner",
    component: AssociateTagScanner,
    meta: {
      title: "Associate Tag (Scanner)",
    },
  },
  {
    path: "/sticker/print",
    name: "PrintSticker",
    component: PrintSticker,
    meta: {
      title: "Print Sticker",
    },
  },
  {
    path: "/death/list",
    name: "DeathList",
    component: DeathList,
    meta: {
      title: "Deaths",
    },
  },
  {
    path: "/data",
    name: "GrafanaView",
    component: GrafanaView,
    meta: {
      title: "Grafana",
    },
  },
  {
    path: "/death/:deathId",
    name: "DeathEdit",
    component: DeathEdit,
    meta: {
      title: "Deaths",
      admin: true,
    },
  },
  {
    path: "/order/list",
    name: "OrderList",
    component: OrderList,
    meta: {
      title: "Orders",
    },
  },
  {
    path: "/order/:orderId",
    name: "OrderEdit",
    component: OrderEdit,
    meta: {
      title: "Orders",
    },
  },
  {
    path: "/inventory/audit",
    name: "InventoryAudit",
    component: InventoryAudit,
    meta: {
      title: "Inventory Audit",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 403) {
    store.dispatch(MESSAGE_ADD, {
      title: "Error",
      body: "You session has expired do not have permission to view that page. Please login and try again.",
      category: "danger",
    });
    store.commit(USER_LOGOUT_SUCCESS);
    router.push("/auth/login");
  }
  return Promise.resolve(error);
});

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta?.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta?.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = `${nearestWithTitle.meta.title} | WC Livestock`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;

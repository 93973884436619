<template lang="pug">
v-select(
  :placeholder="placeholder",
  :value="value",
  :options="options",
  @input="$emit('input', $event)",
  :reduce="(pref) => pref.value",
  label="label"
  :clearable="clearable"
  :multiple="multiple"
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
export default {
  name: "EnumSelect",
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    enum: Object,
    placeholder: String,
    required: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      let options = [];
      for (const key in this.enum) {
        options.push({
          value: this.enum[key],
          label: key,
        });
      }
      return options;
    },
  },
};
</script>

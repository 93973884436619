<template lang="pug">
.dymo-label
  img.label-preview(:src="'data:image/png;base64,' + imgStr", @click="print")
</template>

<script>
import { formatDate } from "@/utils/";
export default {
  name: "DymoLabel",
  props: {
    dymo: Object,
    label_xml: String,
    clover_id: String,
    scientific_name: String,
    common_name: String,
    description: String,
    collection_location: String,
    qt_date: String,
    quantity: Number,
    price: Number,
    variant: Object,
  },
  data() {
    return {
      imgStr: "",
      labelStr: "",
    };
  },
  created() {
    this.renderLabel();
  },
  updated() {
    this.renderLabel();
  },
  methods: {
    renderLabel() {
      this.labelStr = this.label_xml.slice();
      this.labelStr = this.labelStr.replace(
        /PLACEHOLDER_CLOVER_ID/g,
        this.clover_id
      );
      this.labelStr = this.labelStr.replace(
        /PLACEHOLDER_SCIENTIFIC_NAME/g,
        this.scientific_name
      );
      let commonName = this.common_name;
      if (this.description) {
        if (commonName) {
          commonName += " , ";
        }
        commonName += `${this.description}`;
      }
      if (this.collectionLocation) {
        if (commonName) {
          commonName += " , ";
        }
        commonName += `${this.collectionLocation}`;
      }
      this.labelStr = this.labelStr.replace(
        /PLACEHOLDER_COMMON_NAME/g,
        commonName
      );
      this.labelStr = this.labelStr.replace(
        /PLACEHOLDER_QT_DATE/g,
        formatDate(this.qt_date)
      );
      this.labelStr = this.labelStr.replace(
        /PLACEHOLDER_QUANTITY/g,
        this.quantity
      );
      this.labelStr = this.labelStr.replace(
        /PLACEHOLDER_PRICE/g,
        `$${this.price}`
      );
      this.dymo.renderLabel(this.labelStr).then((image) => {
        this.imgStr = image.replace(/"/g, "").replace(/\\/g, "");
      });
    },
    print() {
      this.dymo.print("DYMO LabelWriter 450 Turbo", this.labelStr);
    },
  },
};
</script>

<template lang="pug">
#speciess-subtype-edit(v-if="subType")
  .mb-5
    h1 Edit {{ subType.name }}
    b-form(@submit.prevent="saveSpecies")
      b-form-group#name-group(label="Common Name: ", label-for="name")
        b-form-input#name(
          v-model="subType.name",
          type="text",
          required,
          placeholder="Species Sub Type Name"
        )
      b-form-group#name-group(
        label="Species Type: ",
        label-for="species-type"
      )
        EnumSelect(
          v-model="subType.species_type"
          :enum="SpeciesType"
          placeholder="Select Species Type")
      b-button(type="submit", variant="success") Save
</template>

<script>
import { mapGetters } from "vuex";
import {
  SPECIES_SUBTYPE_LIST_REQUEST,
  SPECIES_SUBTYPE_EDIT_REQUEST,
} from "@/store/actions/species";
import EnumSelect from "@/components/select/EnumSelect.vue";
import { SpeciesType } from "@/constants";

export default {
  name: "SpecieSubTypeEdit",
  components: {
    EnumSelect,
  },
  data() {
    return {
      subType: null,
      SpeciesType,
    };
  },
  computed: {
    ...mapGetters(["getSpeciesSubTypes"]),
  },
  created() {
    this.$store.dispatch(SPECIES_SUBTYPE_LIST_REQUEST).then(() => {
      let toEdit = this.getSpeciesSubTypes.find(
        (subType) => subType.id === parseInt(this.$route.params.subTypeId)
      );
      if (toEdit === undefined) {
        toEdit = {
          id: null,
          name: "",
          species_type: null,
        };
      }
      this.subType = toEdit;
    });
  },
  methods: {
    saveSpecies() {
      this.$store
        .dispatch(SPECIES_SUBTYPE_EDIT_REQUEST, this.subType)
        .then(() => {
          this.$router.push("/species/subtype/list");
        });
    },
  },
};
</script>

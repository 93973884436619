<template lang="pug">
v-select(
  placeholder="Region",
  :value="value",
  :options="getRegions",
  @input="$emit('input', $event)",
  :reduce="(region) => region.id",
  label="name"
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import { REGION_LIST_REQUEST } from "@/store/actions/region";
export default {
  name: "RegionSelect",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["getRegions", "regionStatus"]),
  },
  created() {
    if (this.regionStatus == "init") {
      this.$store.dispatch(REGION_LIST_REQUEST);
    }
  },
};
</script>

<template lang="pug">
v-select(
  placeholder="Species Sub Type",
  :value="value",
  :options="options",
  @input="$emit('input', $event)",
  :reduce="(subType) => subType.id",
  label="name"
  :getLabel="(subType) => subType.name",
)
  template(v-slot:search="{ attributes, events }")
    input.vs__search(:required="!value && required", v-bind="attributes", v-on="events")
</template>

<script>
import { mapGetters } from "vuex";
import { SPECIES_SUBTYPE_LIST_REQUEST } from "@/store/actions/species";
import { SpeciesType } from "@/constants";
export default {
  name: "SpeciesSubTypeSelect",
  filters: {
    speciesType: (val) => SpeciesType[val],
  },
  props: {
    speciesType: Number,
    value: Number,
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    options() {
      return this.getSpeciesSubTypes.filter(
        (subType) => subType.species_type === this.speciesType
      );
    },
    ...mapGetters(["getSpeciesSubTypes"]),
  },
  created() {
    this.$store.dispatch(SPECIES_SUBTYPE_LIST_REQUEST);
  },
};
</script>

<template lang="pug">
#shipment-edit(v-if="shipment")
  .mb-5.screen-only
    h1 Edit Shipment
    b-btn-group(v-if="shipment.id > 0")
      b-btn(:to="`/shipment/${shipment.id}/stickers`")
        b-icon(icon="tag")
        | Stickers
      b-btn(@click="print")
        b-icon(icon="pencil-square")
        | Notes Sheet
  b-form.mb-5(@submit.prevent="saveShipment")
    .screen-only
      b-form-group#supplier-group(label="Supplier")
        SupplierSelect(v-model="shipment.supplier")
      b-form-group#arrival-date-group(
        label="Arrival Date: ",
        label-for="arrival-date"
      )
        b-form-datepicker#arrival-date(v-model="shipment.arrival_date")
      b-form-group#qt-date-group(label="QT Date: ", label-for="qt-date")
        b-form-datepicker#qt-date(v-model="shipment.qt_date")
    .print-only
      h1.text-center Shipment Notes {{shipment.arrival_date | formatDate}} - {{shipment.qt_date | formatDate}}
    b-table(small stacked="md" :items="shipment.items", :fields="shipmentItemsFields", dark)
      template(#cell(num_received)="data")
        b-form-input(
          v-model="data.item.num_received",
          type="number",
          placeholder="# Received",
          min="0"
          :disabled="data.item.id !== null"
        )
      template(#cell(species_variant)="data")
        SpeciesVariantSelect(v-model="data.item.species_variant")
      template(#cell(location)="data")
        LocationSelect(v-model="data.item.location" :multiple="true" :required="false")
      template(#cell(cost)="data")
        b-input-group(prepend="$")
          b-form-input(
            v-model="data.item.cost",
            type="number",
            placeholder="Cost",
            prepend="$",
            step="0.01",
            min="0"
          )
      template(#cell(price)="data")
        b-input-group(prepend="$")
          b-form-input(
            v-model="data.item.price",
            type="number",
            placeholder="Price",
            step="0.01",
            min="0"
            :class="{ 'is-invalid': data.item.price < (data.item.cost*3) }"
          )
          b.invalid-feedback Check markup!
      
      template(#cell(actions)="data")
        b-btn-group
          b-button(variant="danger", @click="removeItem(data.index)") Remove
          b-button(variant="warning" @click="recordDeath(data.item.species_variant, data.item.id)" v-b-tooltip.hover title="Record Death")
            b-icon(icon="clipboard-x")
    b-btn-group.screen-only
      b-button(variant="primary", @click="addItem") Add Item
      b-button(variant="info", @click="updatePricingFromClover" :disabled="speciesStatus != 'loaded' || cloverStatus == 'loading'")
        span(v-if="speciesStatus == 'loaded' && cloverStatus != 'loading'") Import Current Price/Costs From Clover
        span(v-else)
          b-spinner
      b-button(type="submit", variant="success") Save
  div.screen-only(v-if="deaths")
    h2 Shipment Deaths
    DeathListTable(:deaths="deaths")
  RecordDeathModal
  .notes.print-only
    h2 Notes
    .notes-wrap
</template>

<script>
import {
  SHIPMENT_DETAILS_REQUEST,
  SHIPMENT_EDIT_REQUEST,
} from "@/store/actions/shipment";
import SupplierSelect from "@/components/select/SupplierSelect.vue";
import { DateTime } from "luxon";
import { CLOVER_LIST_REQUEST } from "@/store/actions/clover";
import { SPECIES_LIST_REQUEST } from "@/store/actions/species";
import { DEATH_LIST_BY_SHIPMENT_REQUEST } from "@/store/actions/death";
import SpeciesVariantSelect from "@/components/select/SpeciesVariantSelect.vue";
import LocationSelect from "@/components/select/LocationSelect.vue";
import RecordDeathModal from "@/components/RecordDeathModal.vue";
import DeathListTable from "@/components/table/DeathListTable.vue";
import eventBus from "@/eventbus";
import { mapGetters } from "vuex";
import { formatDate } from "@/utils";

export default {
  name: "ShipmentEdit",
  components: {
    SupplierSelect,
    SpeciesVariantSelect,
    LocationSelect,
    RecordDeathModal,
    DeathListTable,
  },
  filters: {
    formatDate,
  },
  data() {
    return {
      shipment: null,
      deaths: null,
      shipmentItemsFields: [
        {
          key: "num_received",
          label: "Count",
          tdClass: "num_received",
          thClass: "num_received",
          thStyle: {
            width: "5%",
          },
        },
        {
          key: "species_variant",
          tdClass: "species_variant",
          thClass: "species_variant",
          thStyle: {
            width: "35%",
          },
        },
        {
          key: "cost",
          tdClass: "cost",
          thClass: "cost",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "price",
          tdClass: "price",
          thClass: "price",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "location",
          tdClass: "location",
          thClass: "location",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "actions",
          tdClass: "actions",
          thClass: "actions",
          thStyle: {
            width: "10%",
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getCloverItems",
      "getSpecies",
      "cloverStatus",
      "speciesStatus",
    ]),
  },
  created() {
    this.$store.dispatch(SPECIES_LIST_REQUEST);
    this.$store
      .dispatch(SHIPMENT_DETAILS_REQUEST, this.$route.params.shipmentId)
      .then((shipment) => {
        let toEdit = shipment;
        if (!toEdit) {
          toEdit = {
            id: null,
            supplier: null,
            arrival_date: DateTime.now().toISODate(),
            qt_date: DateTime.now().plus({ weeks: 2 }).toISODate(),
            items: [],
          };
        } else {
          this.getDeaths();
        }
        this.shipment = toEdit;
      });
    eventBus.$on("death-saved", this.getDeaths);
  },

  methods: {
    print() {
      window.print();
    },
    addItem() {
      this.shipment.items.push({
        id: null,
        species_variant: null,
        location: [],
        cost: 0,
        price: 0,
        num_received: 0,
      });
    },
    removeItem(index) {
      console.log(index);
      this.shipment.items.splice(index, 1);
    },
    updatePricingFromClover() {
      this.$store.dispatch(CLOVER_LIST_REQUEST).then(() => {
        const variantMap = {};
        this.getSpecies.forEach((species) => {
          species.variants.forEach((variant) => {
            variantMap[variant.id] = variant;
          });
        });
        this.shipment.items.forEach((item) => {
          if (item.species_variant) {
            const cloverItem = this.getCloverItems.find(
              (cloverItem) =>
                variantMap[item.species_variant].clover_item != null &&
                cloverItem.clover_id ===
                  variantMap[item.species_variant].clover_item.clover_id
            );
            if (cloverItem) {
              item.cost = cloverItem.cost;
              item.price = cloverItem.price;
            }
          }
        });
      });
    },
    recordDeath(variant, shipmentItemID) {
      eventBus.$emit("record-death", null, variant, shipmentItemID);
    },
    saveShipment() {
      this.$store.dispatch(SHIPMENT_EDIT_REQUEST, this.shipment).then(() => {
        this.$router.push("/shipment/list");
      });
    },
    getDeaths() {
      this.$store
        .dispatch(DEATH_LIST_BY_SHIPMENT_REQUEST, this.$route.params.shipmentId)
        .then((deaths) => {
          this.deaths = deaths;
        });
    },
  },
};
</script>

<style>
.print-only {
  display: none;
}
@media print {
  /* .location, */
  .species_variant .vs__actions,
  .cost,
  .actions,
  .screen-only,
  .btn-group.screen-only,
  .is-invalid ~ b.invalid-feedback {
    display: none;
  }
  .print-only {
    display: block;
  }
  .notes-wrap {
    border: 1px solid black;
    width: 100%;
    min-height: 400px;
    page-break-inside: avoid;
  }
  table.table th,
  table.table td {
    padding: 0;
  }
  input.form-control.is-invalid {
    border-color: rgb(206, 212, 218);

    background-image: none;
  }
}
</style>

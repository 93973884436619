<template lang="pug">
#tag-associate
  header
    h1 Associate Tag
  main
    b-form(@submit.prevent="associateTag")
      b-form-group#tag_id-group(label="Tag ID: ", label-for="tag_id")
        b-form-input#tag_id(
          v-model="tag_id",
          type="text",
          required,
          placeholder="A5XXXXXX"
          :class="{ 'is-invalid': !tagValid}"
        )
        template(v-if="tag")
          b-form-text
            | Battery: 
            b-icon(:icon="batteryIcon(tag.hardware.battery)" :variant="batteryClass(tag.hardware.battery)") 
            | &nbsp;{{ tag.hardware.battery }} 
            | Connectivity: 
            b-icon(:icon="connectivityIcon(tag.connectivity.status)" :variant="connectivityClass(tag.connectivity.status)") 
            | &nbsp;{{ tag.connectivity.status }} 
      b-form-group#clover-item-1-group(label="Item 1: ")
        CloverItemSelect(
          v-model="clover_id_1"
        )
      b-form-group#clover-item-2-group(label="Item 2: ")
        CloverItemSelect(
          v-model="clover_id_2"
          :required="false"
        )
      b-form-group(label="Preview" v-if="tag_id && clover_id_1")#preview 
        img(ref="preview" alt="Preview" class="img-fluid")
      b-form-group#location-group(label="Location (optional): ")
        LocationSelect(
          v-model="location"
          :required="false"
        )
      b-button(type="submit", variant="success") Associate
</template>

<script>
import {
  TAG_ASSOCIATE_REQUEST,
  TAG_PREVIEW_REQUEST,
  TAG_LIST_REQUEST,
} from "@/store/actions/tag";
import CloverItemSelect from "@/components/select/CloverItemSelect.vue";
import LocationSelect from "@/components/select/LocationSelect.vue";
// import tagList from "@/test/tags.json";

export default {
  name: "AssociateTag",
  components: {
    CloverItemSelect,
    LocationSelect,
  },
  data() {
    return {
      tag_id: "",
      species: null,
      clover_id_1: "",
      clover_id_2: "",
      tags: [],
      location: null,
    };
  },
  computed: {
    tag() {
      return this.tags.find((tag) => tag.labelId === this.tag_id);
    },
    tagValid() {
      if (this.tag) {
        return (
          this.tag.hardware.battery === "GOOD" &&
          this.tag.connectivity.status === "ONLINE"
        );
      }
      return false;
    },
  },
  watch: {
    tag_id() {
      this.getPreview();
    },
    clover_id_1() {
      this.getPreview();
    },
    clover_id_2() {
      this.getPreview();
    },
  },
  created() {
    this.$store.dispatch(TAG_LIST_REQUEST).then((tags) => {
      this.tags = tags;
    });
  },
  methods: {
    getPreview() {
      if (this.tag_id.length != 8 || !this.clover_id_1) {
        return;
      }
      this.$store.dispatch(TAG_PREVIEW_REQUEST, this).then((image) => {
        this.$refs.preview.src = `data:image/png;base64,${image}`;
      });
    },
    associateTag() {
      const ids = [this.clover_id_1];
      if (this.clover_id_2) {
        ids.push(this.clover_id_2);
      }
      this.$store
        .dispatch(TAG_ASSOCIATE_REQUEST, {
          tag_id: this.tag_id,
          clover_ids: ids,
          location: this.location,
        })
        .then(() => {
          this.tag_id = "";
          this.species = null;
          this.clover_id_1 = "";
          this.clover_id_2 = "";
          this.location = null;
        });
    },
    batteryIcon(status) {
      switch (status) {
        case "GOOD":
          return "battery-full";
        case "BAD":
          return "battery-half";
        default:
          return "battery";
      }
    },
    batteryClass(status) {
      switch (status) {
        case "GOOD":
          return "success";
        case "BAD":
        default:
          return "danger";
      }
    },
    connectivityIcon(status) {
      switch (status) {
        case "ONLINE":
          return "wifi";
        case "OFFLINE":
          return "wifi-off";
      }
    },
    connectivityClass(status) {
      switch (status) {
        case "ONLINE":
          return "success";
        case "OFFLINE":
          return "danger";
        default:
          return "battery-empty";
      }
    },
  },
};
</script>

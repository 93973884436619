<template lang="pug">
#speciess-list(v-if="species")
  .mb-5
    h1 Edit {{ species.common_name }} &nbsp;
      em(v-if="species.scientific_name") ({{ species.scientific_name }})
    b-form(@submit.prevent="saveSpecies")
      template
        b-form-group#common-name-group(
          label="Common Name: ",
          label-for="common-name"
        )
          b-form-input#common-name(
            v-model="species.common_name",
            type="text",
            placeholder="Species Common Name"
          )
        b-form-group#scientific-name-group(
          label="Scientific Name: ",
          label-for="scientific-name"
        )
          b-form-input#scientific-name(
            v-model="species.scientific_name",
            type="text",
            placeholder="Species Scientific Name"
          )
        b-form-group#environment-type-group(
          label="Environment Type: ",
          label-for="environment-type"
        )
          EnumSelect(
            v-model="species.environment_type",
            :enum="EnvironmentType",
            placeholder="Select Environment Type"
          )
        b-form-group#species-type-group(
          label="Species Type: ",
          label-for="species-type"
        )
          EnumSelect(
            v-model="species.species_type",
            :enum="SpeciesType",
            placeholder="Select Species Type"
          )
        b-form-group#sub-type-group(
          label="Species Sub Type: ",
          label-for="sub-type",
          v-if="species.species_type"
        )
          SpeciesSubTypeSelect(
            v-model="species.species_sub_type",
            :speciesType="species.species_type"
          )
        b-form-group#max-size-group(
          label="Typical Max Size: ",
          label-for="max-size",
          v-if="species.species_type == SpeciesType.Fish"
        )
          b-form-input#max-size(
            v-model="species.max_size",
            type="text",
            placeholder="Species Max Size"
          )
        b-form-group#diet-group(
          label="Diet: ",
          label-for="diet",
          v-if="species.species_type == SpeciesType.Fish"
        )
          b-form-input#diet(
            v-model="species.diet",
            type="text",
            placeholder="Species Diet"
          )
        b-form-group#light-level-group(
          label="Light Level",
          label-for="light-level",
          v-if="species.species_type == SpeciesType.Plant || species.species_type == SpeciesType.Coral"
          
        )
          EnumSelect(
            v-model="species.light_level",
            :enum="LightLevel",
            placeholder="Select Light Level"
            :required="false"
          )
        b-form-group#placement-group(
          label="Placement",
          label-for="placement",
          v-if="species.species_type == SpeciesType.Plant"
        )
          EnumSelect(
            v-model="species.placement",
            :enum="Placement",
            placeholder="Select Placment"
            :required="false"
          )
        b-form-group#flow-rate-group(
          label="Flow Rate",
          label-for="flow-rate",
          v-if="species.species_type == SpeciesType.Coral"
        )
          EnumSelect(
            v-model="species.flow_rate",
            :enum="FlowRate",
            placeholder="Select Flow Rate"
            :required="false"
          )
        b-form-group#region-group(label="Region: ", label-for="region")
          RegionSelect(v-model="species.region")
        b-form-group#variants-group(label="Variants: ")
        .accordion(role="tablist" id="species-variants")
          b-card.mb-2(
            no-body
            bg-variant="secondary"
            text-variant="white"
            v-for="(variant, index) in species.variants"
            :key="index")
            template(#header)
              div.d-flex.justify-content-between
                div \#{{variant.id}} {{ variant.description }} - {{ variant.collection_location }}
                b-button-group(size="sm")
                  b-button(variant="primary" v-b-toggle="`variant-${index}`")
                    b-icon(icon="chevron-down")
                    b-icon(icon="chevron-up")
            b-collapse.p-2(
              :id="`variant-${index}`"
              accordion="species-variants"
            )
              .w-75
                b-form-group#clover-id-group(label="Clover Product: ", label-for="clover-id")
                  CloverItemSelect(
                    v-model="variant.clover_item",
                    placeholder="Clover Item"
                    :livestock-filter="'livestock'"
                  )
                b-form-group(
                  label="Description: ",
                  label-for="description"
                )
                  b-form-input(
                    v-model="variant.description",
                    placeholder="Red, Longfin, etc."
                    :required="species.variants.length > 1 && !variant.collection_location"
                  )
                b-form-group(
                  label="Collection Location: ",
                  label-for="collection-location"
                )
                  b-form-input(
                    v-model="variant.collection_location",
                    placeholder="Rio Orinoco, etc."
                    :required="species.variants.length > 1 && !variant.description"
                  )
                b-form-group(
                  label="Size: ",
                  label-for="size"
                )
                  EnumButtonGroup(
                    v-model="variant.size",
                    :enum="Size",
                    :clearable="true",
                    :required="false"
                  )
                b-form-group(
                  label="Locations: ",
                  label-for="locations"
                )
                  LocationSelect(v-model="variant.locations", :multiple="true")
                b-form-group(
                  label="Image: ",
                  label-for="image"
                )
                  b-img(
                    v-if="variant.image_preview_url"
                    :src="variant.image_preview_url"
                    fluid
                    thumbnail
                  )
                  b-img(
                    v-if="variant.image_s3_key"
                    :src="`${S3_PUBLIC_PATH}${variant.image_s3_key}`"
                    fluid
                    thumbnail
                  )
                  b-form-file(
                    v-model="variant.image",
                    placeholder="Choose Image",
                    accept="image/*"
                    @input="handleImagePreview(variant)"
                  )
                b-form-group(
                  label="Sync to WooCommerce: ",
                  label-for="sync-to-woocommerce"
                )
                  b-form-checkbox(
                    v-model="variant.sync_to_woocommerce",
                    :value="true",
                    :unchecked-value="false"
                    switch
                    :disabled="variant.forceSync"
                  ) Sync Online
                  b-form-input(
                    v-model="variant.woocommerce_id",
                    type="text",
                    placeholder="WooCommerce ID"
                    v-show="variant.sync_to_woocommerce && !variant.forceSync"
                  )
                  b-button(
                    :href="`https://watercolorsaquariumgallery.com/wp-admin/post.php?post=${variant.woocommerce_id}&action=edit`"
                    target="_blank"
                    variant="info"
                    v-if="variant.forceSync"
                  ) Open WooCommerce Product
                  b-form-checkbox(  
                    v-model="variant.woocommerce_in_stock",
                    :value="true",
                    :unchecked-value="false"
                    switch
                  ) Available Online
                b-form-group(
                  label="Actions: ",
                  label-for="actions"
                )
                  b-button(variant="danger", @click="removeVariant(variant, index)") Remove

          b-btn.mb-2(variant="info", size="sm", @click="addVariant") Add Variant
        b-form-group#notes-group(label="Notes: ", label-for="notes")
          b-form-textarea#notes(
            v-model="species.notes",
            type="text",
            placeholder="Species Notes"
          )
        b-button(type="submit", variant="success") Save
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  SPECIES_DETAILS_REQUEST,
  SPECIES_VARIANT_LIST_REQUEST,
  SPECIES_VARIANT_DELETE_REQUEST,
  SPECIES_EDIT_REQUEST,
} from "@/store/actions/species";
import SpeciesSubTypeSelect from "@/components/select/SpeciesSubTypeSelect.vue";
import CloverItemSelect from "@/components/select/CloverItemSelect.vue";
import LocationSelect from "@/components/select/LocationSelect.vue";
import RegionSelect from "@/components/select/RegionSelect.vue";
import EnumSelect from "@/components/select/EnumSelect.vue";
import EnumButtonGroup from "@/components/select/EnumButtonGroup.vue";
import {
  EnvironmentType,
  SpeciesType,
  LightLevel,
  FlowRate,
  Placement,
  Size,
} from "@/constants";
import { formatVariant } from "@/utils";

const newVariant = () => ({
  id: null,
  clover_id: "",
  description: null,
  collection_location: null,
  size: null,
  locations: [],
  woocommerce_id: null,
  sync_to_woocommerce: false,
  woocommerce_in_stock: false,
  image_preview_url: null,
  image_s3_key: null,
});

export default {
  name: "SpeciesEdit",
  components: {
    SpeciesSubTypeSelect,
    CloverItemSelect,
    LocationSelect,
    RegionSelect,
    EnumSelect,
    EnumButtonGroup,
  },
  filters: {
    formatVariant,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      species: null,
      EnvironmentType,
      SpeciesType,
      LightLevel,
      FlowRate,
      Placement,
      Size,
      variantFields: [
        {
          key: "clover_id",
          label: "Clover Product",
          thStyle: {
            width: "25%",
          },
        },
        "description",
        "collection_location",
        "locations",
        "actions",
      ],
      S3_PUBLIC_PATH: process.env.VUE_APP_S3_PUBLIC_PATH,
    };
  },
  computed: {
    ...mapGetters(["getSpecies", "getCloverItems", "getCloverItemById"]),
  },
  watch: {
    "species.species_type"() {},
  },
  created() {
    this.$store
      .dispatch(SPECIES_DETAILS_REQUEST, this.$route.params.speciesId)
      .then((toEdit) => {
        if (toEdit === null) {
          toEdit = {
            id: null,
            common_name: "",
            scientific_name: "",
            diet: "",
            max_size: 0,
            notes: "",
            species_type: null,
            species_sub_type: null,
            region: null,
            light_level: null,
            placement: null,
            flow: null,
            variants: [newVariant()],
          };
        } else {
          toEdit.region = toEdit.region != null ? toEdit.region.id : null;
          toEdit.species_sub_type =
            toEdit.species_sub_type != null ? toEdit.species_sub_type.id : null;
          toEdit.variants.forEach((variant) => {
            if (variant.sync_to_woocommerce) {
              variant.forceSync = true;
            }
            if (variant.locations) {
              variant.locations = variant.locations.map((l) => l.location.id);
              variant.clover_item =
                variant.clover_item != null
                  ? variant.clover_item.clover_id
                  : null;
            }
          });
        }
        this.species = toEdit;
      });
  },

  methods: {
    saveSpecies() {
      this.$store.dispatch(SPECIES_EDIT_REQUEST, this.species).then(() => {
        this.$router.push("/species/list");
      });
    },
    addVariant() {
      this.species.variants.push(newVariant());
    },
    removeVariant(variant, index) {
      if (!variant.id) {
        this.species.variants.splice(index, 1);
        return;
      }
      if (
        confirm(
          "Are you sure you want to delete this variant? It will be removed from any existing shipments."
        )
      ) {
        this.$store
          .dispatch(SPECIES_VARIANT_DELETE_REQUEST, {
            variant,
            species: this.species,
          })
          .then(() => {
            this.$store
              .dispatch(SPECIES_VARIANT_LIST_REQUEST, this.species.id)
              .then((resp) => {
                Vue.set(this.species, "variants", []);
                resp.data.forEach((variant) => {
                  this.species.variants.push({
                    id: variant.id,
                    clover_id: variant.clover_id,
                    description: variant.description,
                    locations: variant.locations.map((l) => l.location.id),
                  });
                });
              });
          });
      }
    },
    handleImagePreview(variant) {
      const file = variant.image;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          Vue.set(variant, "image_preview_url", e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
